import { computed, reactive } from "vue";

import {
  minLength,
  required,
  email,
  helpers,
  sameAs,
} from "@vuelidate/validators";

import { startTokenExpirationWatcher } from "@/utils/tokenWatcher";

import { AuthService } from "@/services";

import { isLoading } from "@/shared/base.service";

import { useAuthStore } from "@/stores/auth.store";

import type { CreateUserRequest } from "@/types/request/create/createUser";

export function useAuth() {
  const signUpFormRules = computed(() => {
    return {
      type: {
        required: helpers.withMessage(
          () => `O campo tipo é obrigatório`,
          required
        ),
      },
      name: {
        required: helpers.withMessage(
          () => `O campo nome é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo nome deve ter ao menos 3 caracteres`,
          minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
      document: {
        required: helpers.withMessage(
          () => `O campo documento é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo documento deve ter ao menos 11 caracteres`,
          minLength(11)
        ),
      },
      phone: {
        required: helpers.withMessage(
          () => `O campo telefone é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo telefone deve ter ao menos 10 caracteres`,
          minLength(10)
        ),
      },
      password: {
        required: helpers.withMessage(
          () => `O campo senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo senha deve ter ao menos 6 caracteres`,
          minLength(6)
        ),
      },
      confirmationPassword: {
        required: helpers.withMessage(
          () => `O campo confirmação de senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo deve ter ao menos 6 caracteres`,
          minLength(6)
        ),
        sameAsPassword: helpers.withMessage(
          () => `As senhas devem ser iguais`,
          sameAs(signUpForm.password)
        ),
      },
    };
  });

  const signUpForm = reactive({
    type: "BUSINESS",
    name: "",
    email: "",
    document: "",
    phone: "",
    password: "",
    confirmationPassword: "",
  });

  const signInFormRules = computed(() => {
    return {
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
      password: {
        required: helpers.withMessage(
          () => `O campo senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo deve ter ao menos 6 caracteres`,
          minLength(3)
        ),
      },
    };
  });

  const signInForm = reactive({
    email: "",
    password: "",
    remember: false,
  });

  const forgotPasswordFormRules = computed(() => {
    return {
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
    };
  });

  const forgotPasswordForm = reactive({
    email: "",
  });

  const confirmTokenFormRules = computed(() => {
    return {
      token: {
        required: helpers.withMessage(
          () => `O campo token é obrigatório`,
          required
        ),
      },
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
    };
  });

  const confirmTokenForm = reactive({
    token: "",
    email: "",
  });

  const passwordResetFormRules = computed(() => {
    return {
      token: {
        required: helpers.withMessage(
          () => `O campo token é obrigatório`,
          required
        ),
      },
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
      password: {
        required: helpers.withMessage(
          () => `O campo senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo deve ter ao menos 6 caracteres`,
          minLength(6)
        ),
      },
      confirmationPassword: {
        sameAsPassword: helpers.withMessage(
          () => `as senhas devem ser iguais`,
          sameAs(passwordResetForm.password)
        ),
        required: helpers.withMessage(
          () => `O campo confirmação de senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo deve ter ao menos 6 caracteres`,
          minLength(6)
        ),
      },
    };
  });

  const passwordResetForm = reactive({
    token: "",
    email: "",
    password: "",
    confirmationPassword: "",
  });

  const autoSignIn = async () => {
    const storedAuth = useAuthStore().getAutoSign;

    if (storedAuth) {
      const { login, token } = storedAuth;
      signInForm.email = login;
      useAuthStore().setToken(token);

      return true;
    }

    return false;
  };

  async function signIn(email: string, password: string) {
    try {
      const response = await AuthService.SignIn(email, password);
      if (response && typeof response === "object") {
        const expiresAt = Date.now() + 60 * 60 * 1000;

        useAuthStore().setToken(response.access_token);
        useAuthStore().setExpiresAt(expiresAt);

        const user = {
          userId: response.userId,
          name: response.name,
          type: response.type,
        };

        useAuthStore().setUser(user);

        startTokenExpirationWatcher();

        if (signInForm.remember == true) {
          useAuthStore().setAutoSign({
            login: signInForm.email,
            token: response.access_token,
          });
        }
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function signUp(payload: object) {
    try {
      const response = await AuthService.SignUp(payload as CreateUserRequest);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function forgotPassword(payload: object) {
    try {
      const response = await AuthService.ForgotPassword(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function confirmToken(payload: object) {
    try {
      const response = await AuthService.ConfirmToken(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function resetPassword(payload: object) {
    try {
      const response = await AuthService.ResetPassword(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function refreshToken() {
    try {
      const response = await AuthService.RefreshToken();

      if (response) {
        const expiresAt = Date.now() + 60 * 60 * 1000; // 1 hora

        useAuthStore().setToken(response.access_token);
        useAuthStore().setExpiresAt(expiresAt);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function logout() {
    try {
      // const response = await AuthService.Logout();
      const response = true;

      if (response) useAuthStore().setLogout();

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    signUpFormRules,
    signUpForm,
    signInFormRules,
    signInForm,
    forgotPasswordFormRules,
    forgotPasswordForm,
    confirmTokenFormRules,
    confirmTokenForm,
    passwordResetFormRules,
    passwordResetForm,
    autoSignIn,
    signIn,
    signUp,
    forgotPassword,
    confirmToken,
    resetPassword,
    refreshToken,
    logout,
  };
}
