<template>
  <PageTitle pageTitle="Lista de Produtos" mainTitle="Produtos" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Produtos</h5>

      <router-link
        class="default-btn pingo small"
        :to="{ name: 'CreateProductPage' }"
      >
        <i class="ri-add-fill"></i>
        Criar novo produto
      </router-link>

      <!-- <p>Atualizado por último em {{ new Date().toLocaleString() }}</p> -->
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="products"
          :loading="isLoadingProducts"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.itemId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.price`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.isActive`]="{ value }">
            <span
              v-if="value"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Ativo
            </span>

            <span
              v-if="!value"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Inativo
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <v-btn
                class="text-capitalize"
                :color="item.isActive ? 'red-darken-3' : 'green-darken-3'"
                variant="tonal"
                type="button"
                @click="handleChangeProductStatus(item)"
              >
                {{ item.isActive ? "Desativar" : "Ativar" }}
              </v-btn>

              <router-link :to="`/produtos/editar/${item.itemId}`">
                <v-btn
                  class="text-capitalize"
                  color="primary-darken-3"
                  variant="tonal"
                  type="button"
                >
                  Editar
                </v-btn>
              </router-link>

              <v-dialog width="auto" scrollable>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="text-capitalize"
                    color="red-darken-3"
                    variant="tonal"
                    v-bind="activatorProps"
                  >
                    Remover produto
                  </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card class="py-0" title="Remover produto">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      Tem certeza que deseja remover este produto?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="isActive.value = false"
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="red-darken-3"
                        variant="tonal"
                        @click="handleRemoveProduct(item.itemId)"
                      >
                        Remover
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum produto </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>

  <!-- <ProductsContent /> -->
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import EventBus from "@/utils/eventBus";

import { useAuthStore } from "@/stores/auth.store";
import { useLoadingDialog } from "@/stores/loading.store";

import { useProduct } from "@/composables/useProduct";

import PageTitle from "@/components/Common/PageTitle.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

// import ProductsContent from "@/components/eCommerce/Products/ProductsContent.vue";

import type { ProductResponse } from "@/types/response/product";
import type { UpdateProductRequest } from "@/types/request/update/updateProduct";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";

export default defineComponent({
  name: "ProductsPage",
  components: {
    PageTitle,
    ItemsPerPage,
    // ProductsContent,
  },
  setup() {
    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const {
      columns,
      pagination,
      products,
      getAllProducts,
      updateProduct,
      deleteProductById,
    } = useProduct();

    const user: Ref = ref(authStore.getUser);

    const isLoadingProducts = ref(false);

    const fetchProductsData = async () => {
      try {
        isLoadingProducts.value = true;

        getAllProducts(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        ).then(() => {
          isLoadingProducts.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de produtos:", error);
      }
    };

    const handleChangeProductStatus = async (product: ProductResponse) => {
      const newStatus = !product.isActive;

      if (newStatus) {
        loadingStore.setLoading("Ativando produto...");
      } else {
        loadingStore.setLoading("Inativando produto...");
      }

      const productUpdateRequestPayload: UpdateProductRequest = {
        name: product.name,
        description: product.description,
        price: product.price.toString(),
        isActive: newStatus == true ? 1 : 0,
      };

      const response = await updateProduct(
        product.itemId,
        productUpdateRequestPayload
      );

      if (response) await handleUpdatePageProps({ page: 1, size: 25 });

      loadingStore.setLoadingOff();
    };

    const handleRemoveProduct = async (product_id: number) => {
      loadingStore.setLoading("Removendo produto...");

      await deleteProductById(product_id);

      loadingStore.setLoadingOff();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchProductsData();
    };

    onMounted(async () => {
      await fetchProductsData();

      // // Listen for the product-update event
      EventBus.on("product-update", async () => {
        await handleUpdatePageProps({ page: 1, size: 25 });
      });
    });

    // onUnmounted(() => {
    //   // Remove the event listener when the component is destroyed
    //   EventBus.off("product-update", fetchProductsData);
    // });

    return {
      columns,
      pagination,
      isLoadingProducts,
      user,
      products,
      handleChangeProductStatus,
      handleUpdatePageProps,
      handleRemoveProduct,
    };
  },
});
</script>
