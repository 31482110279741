<template>
  <PageTitle pageTitle="Novo Usuário" mainTitle="Novo Usuário" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações Básicas</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="12" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nome
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o nome do usuário aqui'"
                  :type="'email'"
                  v-model="newUserBusinessForm.name"
                  :error-messages="
                    v$.name.$errors ? v$.name.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o email do usuário aqui'"
                  :type="'email'"
                  v-model="newUserBusinessForm.email"
                  :error-messages="
                    v$.email.$errors ? v$.email.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Documento <span>*</span>
                </label>

                <CpfInput
                  v-if="newUserBusinessForm.type != 'BUSINESS'"
                  :label="'Digite o CPF do usuário aqui'"
                  v-model="newUserBusinessForm.document"
                  :required="true"
                  @update:value="(value: string) => newUserBusinessForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors
                      ? v$.document.$errors[0]?.$message
                      : undefined
                  "
                />

                <CnpjInput
                  v-if="newUserBusinessForm.type == 'BUSINESS'"
                  :label="'Digite o CNPJ do usuário aqui'"
                  v-model="newUserBusinessForm.document"
                  :required="true"
                  @update:value="(value: string) => newUserBusinessForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors
                      ? v$.document.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Celular <span>*</span>
                </label>

                <PhoneInput
                  :label="'Digite o celular do usuário aqui'"
                  :type="'tel'"
                  v-model="newUserBusinessForm.phone"
                  :error-messages="
                    v$.phone.$errors ? v$.phone.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col v-if="user.type == 'ADMIN'" cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Tipo de usuário
                  <span>*</span>
                </label>

                <v-select
                  label="Selecione um tipo"
                  @update:model-value="(value: 'ADMIN' | 'PARTNER' | 'BUSINESS' | 'COLLABORATOR' | 'BENEFICIARY' | any) => newUserBusinessForm.type = value"
                  :items="[
                    {
                      title: 'Administrador',
                      value: 'ADMIN',
                    },
                    {
                      title: 'Parceiro',
                      value: 'PARTNER',
                    },
                    {
                      title: 'Empresa',
                      value: 'BUSINESS',
                    },
                    {
                      title: 'Colaborador',
                      value: 'COLLABORATOR',
                    },
                    {
                      title: 'Beneficiário',
                      value: 'BENEFICIARY',
                    },
                  ]"
                  v-model="newUserBusinessForm.type"
                  :error-messages="
                    v$.type.$errors ? v$.type.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Senha <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a senha do usuário aqui'"
                  :type="'password'"
                  v-model="newUserBusinessForm.password"
                  :error-messages="
                    v$.password.$errors
                      ? v$.password.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirme a sua senha
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a senha novamente'"
                  :type="'password'"
                  v-model="newUserBusinessForm.confirmationPassword"
                  :error-messages="
                    v$.confirmationPassword.$errors
                      ? v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações de Endereço</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  CEP
                  <span>*</span>
                </label>

                <PostalCodeInput
                  :label="'Digite o seu CEP aqui'"
                  v-model="newUserBusinessForm.zipCode"
                  @update:value="(value: string) => updatePostalCode(value)"
                  :error-messages="
                    v$.zipCode.$errors
                      ? v$.zipCode.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Rua
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Nome da rua'"
                  v-model="newUserBusinessForm.street"
                  :error-messages="
                    v$.street.$errors
                      ? v$.street.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Bairro
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o bairro aqui'"
                  v-model="newUserBusinessForm.neighborhood"
                  :error-messages="
                    v$.neighborhood.$errors
                      ? v$.neighborhood.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Cidade
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite a cidade aqui'"
                  v-model="newUserBusinessForm.city"
                  :error-messages="
                    v$.city.$errors ? v$.city.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Estado
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o estado aqui'"
                  v-model="newUserBusinessForm.state"
                  :error-messages="
                    v$.state.$errors ? v$.state.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Número
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o número aqui'"
                  v-model="newUserBusinessForm.number"
                  :error-messages="
                    v$.number.$errors
                      ? v$.number.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="12" md="12">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Complemento
                </label>
                <TextInput
                  :label="'Digite o complemento aqui (opcional)'"
                  v-model="newUserBusinessForm.complement"
                />
              </div>
            </v-col>

            <!-- <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Endereço Principal?
                  <span>*</span>
                </label>
                <v-select
                  v-model="newUserBusinessForm.isDefault"
                  :items="[
                    { title: 'Sim', value: true },
                    { title: 'Não', value: false },
                  ]"
                  :error-messages="
                    v$.isDefault.$errors
                      ? v$.isDefault.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col> -->
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingUserRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>
              Criar Usuário
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";
import { useLoadingDialog } from "@/stores/loading.store";

import { useUser } from "@/composables/useUser";
import { useAddress } from "@/composables/useAddress";
import { useViaCEP } from "@/composables/useViaCEP";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import CpfInput from "@/components/Forms/AdvancedElements/Input/CpfInput.vue";
import CnpjInput from "@/components/Forms/AdvancedElements/Input/CnpjInput.vue";
import PhoneInput from "@/components/Forms/AdvancedElements/Input/PhoneInput.vue";
import PostalCodeInput from "@/components/Forms/AdvancedElements/Input/PostalCodeInput.vue";

export default defineComponent({
  name: "NewUserBusinessPage",
  components: {
    PageTitle,
    TextInput,
    CpfInput,
    CnpjInput,
    PhoneInput,
    PostalCodeInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const user: Ref = ref(authStore.getUser);

    const { address, getAddress } = useViaCEP();

    const { newUserBusinessForm, newUserBusinessFormRules, createUser } =
      useUser();
    const { createAddress } = useAddress();

    const isLoadingUserInfo = ref(false);
    const isLoadingUserRequest = ref(false);

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Criando usuário...");

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        city,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        complement,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        country,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        neighborhood,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isDefault,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        street,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        zipCode,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        confirmationPassword,
        ...newUserBusinessFormPayload
      } = newUserBusinessForm;

      newUserBusinessFormPayload.userParentId = user.value.userId;
      newUserBusinessFormPayload.document =
        newUserBusinessFormPayload.document.replace(/\D/g, "");
      newUserBusinessFormPayload.phone =
        newUserBusinessFormPayload.phone.replace(/\D/g, "");

      const response = await createUser(newUserBusinessFormPayload);
      if (!response || typeof response === "boolean") {
        loadingStore.setLoadingOff();

        return;
      }

      const newUserId = response.userId;

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        document,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        email,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        name,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userParentId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        phone,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        password,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        type,
        ...newUserBusinessFormPayloadAddress
      } = newUserBusinessForm;

      newUserBusinessFormPayloadAddress.userId = newUserId;
      newUserBusinessFormPayloadAddress.isDefault = true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((newUserBusinessFormPayloadAddress as any).confirmationPassword) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (newUserBusinessFormPayloadAddress as any).confirmationPassword;
      }
      const addressResponse = await createAddress(
        newUserBusinessFormPayloadAddress
      );

      loadingStore.setLoadingOff();

      if (addressResponse) {
        setTimeout(() => {
          router.go(-1);
        }, 2000);
      }
    };

    const updatePostalCode = (value: string) => {
      if (value.length == 9)
        getAddress(value).then(() => {
          if (address.value) {
            newUserBusinessForm.street = address.value.logradouro;
            newUserBusinessForm.complement = address.value.complemento;
            newUserBusinessForm.neighborhood = address.value.bairro;
            newUserBusinessForm.city = address.value.localidade;
            newUserBusinessForm.state = address.value.uf;
          }
        });
    };

    const v$ = useVuelidate(newUserBusinessFormRules, newUserBusinessForm, {
      $scope: false,
    });

    onMounted(() => {
      const type = route.query.type;
      if (type)
        newUserBusinessForm.type = type.toString() as
          | "ADMIN"
          | "PARTNER"
          | "BUSINESS"
          | "COLLABORATOR"
          | "BENEFICIARY";
    });

    return {
      v$,
      user,
      route,
      router,
      isLoadingUserInfo,
      isLoadingUserRequest,
      newUserBusinessForm,
      handleSubmit,
      updatePostalCode,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
