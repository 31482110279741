import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "d-flex ga-2" }
const _hoisted_3 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_4 = { class: "pingo-form-group without-icon" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "pingo-table-content" }
const _hoisted_7 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_8 = { class: "fw-semibold main-color" }
const _hoisted_9 = { class: "d-flex justify-content-center align-items-center ga-6" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex ga-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_FinancialFilters = _resolveComponent("FinancialFilters")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AutoCompleteBusinesses = _resolveComponent("AutoCompleteBusinesses")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_SelectInvoiceStatus = _resolveComponent("SelectInvoiceStatus")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Financeiro",
      mainTitle: "Financeiro"
    }),
    (_ctx.user.type == _ctx.UserType.ADMIN)
      ? (_openBlock(), _createBlock(_component_FinancialFilters, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _cache[12] || (_cache[12] = _createElementVNode("h5", { class: "mb-2" }, "Financeiro", -1)),
            _createElementVNode("p", null, "Atualizado por último em " + _toDisplayString(new Date().toLocaleString()), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.isProcessInvoicesManualModalOpen,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isProcessInvoicesManualModalOpen) = $event)),
              "max-width": "480",
              scrollable: ""
            }, {
              activator: _withCtx(({ props: activatorProps }) => [
                (_ctx.user.type == _ctx.UserType.ADMIN)
                  ? (_openBlock(), _createElementBlock("button", _mergeProps({
                      key: 0,
                      class: "default-btn pingo small"
                    }, activatorProps), _cache[13] || (_cache[13] = [
                      _createElementVNode("i", { class: "ri-restart-line" }, null, -1),
                      _createTextVNode(" Processar todas as faturas ")
                    ]), 16))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(({ isActive }) => [
                _createVNode(_component_v_form, {
                  onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleProcessInvoices()), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      class: "py-0",
                      title: "Processar faturas manualmente"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_card_text, { class: "px-4" }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Tem certeza que deseja realizar o processamento das faturas manualmente? ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              class: "text-capitalize",
                              onClick: ($event: any) => (isActive.value = false)
                            }, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode(" Fechar ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              color: "red-darken-3",
                              variant: "tonal",
                              type: "submit",
                              style: {"text-transform":"none"}
                            }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode(" Sim, tenho certeza ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.isProcessInvoicesIndividualModalOpen,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isProcessInvoicesIndividualModalOpen) = $event)),
              "max-width": "480",
              scrollable: ""
            }, {
              activator: _withCtx(({ props: activatorProps }) => [
                (_ctx.user.type == _ctx.UserType.ADMIN)
                  ? (_openBlock(), _createElementBlock("button", _mergeProps({
                      key: 0,
                      class: "default-btn pingo small"
                    }, activatorProps), _cache[17] || (_cache[17] = [
                      _createElementVNode("i", { class: "ri-user-line" }, null, -1),
                      _createTextVNode(" Gerar faturamento individual ")
                    ]), 16))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(({ isActive }) => [
                _createVNode(_component_v_form, {
                  onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      class: "py-0",
                      title: "Processar faturas manualmente"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_card_text, { class: "px-4" }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" Tem certeza que deseja realizar o processamento das faturas para o usuário selecionado? ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, { class: "px-4" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_AutoCompleteBusinesses, {
                                modelValue: _ctx.createInvoiceForm.userId,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createInvoiceForm.userId) = $event)),
                                "onUpdate:value": _cache[3] || (_cache[3] = (value) => (_ctx.updateUserId(value))),
                                required: true,
                                "return-object": false,
                                "error-messages": 
                        _ctx.v$2.userId.$errors
                          ? _ctx.v$2.userId.$errors[0]?.$message
                          : undefined
                      
                              }, null, 8, ["modelValue", "error-messages"])
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_TextInput, {
                                type: 'date',
                                label: 'Data Inicial',
                                modelValue: _ctx.createInvoiceForm.dateStart,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.createInvoiceForm.dateStart) = $event)),
                                required: false,
                                "onUpdate:value": _cache[5] || (_cache[5] = 
                        (initial_datetime) => _ctx.updateInitDate(initial_datetime)
                      )
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_TextInput, {
                                type: 'date',
                                label: 'Data Final',
                                required: false,
                                modelValue: _ctx.createInvoiceForm.dateEnd,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.createInvoiceForm.dateEnd) = $event)),
                                "onUpdate:value": _cache[7] || (_cache[7] = 
                        (final_datetime) => _ctx.updateFinishDate(final_datetime)
                      )
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              class: "text-capitalize",
                              onClick: ($event: any) => (isActive.value = false)
                            }, {
                              default: _withCtx(() => _cache[19] || (_cache[19] = [
                                _createTextVNode(" Fechar ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              color: "red-darken-3",
                              variant: "tonal",
                              type: "submit",
                              style: {"text-transform":"none"}
                            }, {
                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                _createTextVNode(" Sim, tenho certeza ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.filteredColumns,
              items: _ctx.invoices,
              loading: _ctx.isLoadingFinancials,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.invoiceId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(value), 1)
              ]),
              [`item.totalAmount`]: _withCtx(({ value }) => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)), 1)
              ]),
              [`item.status`]: _withCtx(({ item }) => [
                _createElementVNode("span", {
                  class: _normalizeClass({
                'text-capitalize badge rounded-pill text-soft-danger':
                  item.status === 'cancelled',
                'text-capitalize badge rounded-pill text-soft-warning':
                  item.status === 'pending',
                'text-capitalize badge rounded-pill text-soft-info':
                  item.status === 'partially_paid',
                'text-capitalize badge rounded-pill text-soft-pingo':
                  item.status === 'refunded',
                'text-capitalize badge rounded-pill text-soft-success':
                  item.status === 'paid',
                'text-capitalize badge rounded-pill text-soft-primary':
                  item.status === 'overdue',
              })
                }, _toDisplayString(_ctx.mapStatusTitle(item.status as InvoiceStatus)), 3)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_v_dialog, {
                    width: "380",
                    scrollable: "",
                    modelValue: _ctx.isInvoiceStatusDialogOpen,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isInvoiceStatusDialogOpen) = $event))
                  }, {
                    activator: _withCtx(({ props: activatorProps }) => [
                      (_ctx.user.type === 'ADMIN' || _ctx.user.type === 'ATTENDANT')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            (item.status != _ctx.InvoiceStatus.Paid)
                              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
                                  key: 0,
                                  class: "text-capitalize",
                                  color: "blue-darken-3",
                                  variant: "tonal"
                                }, activatorProps, { type: "button" }), {
                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                    _createTextVNode(" Atualizar Status ")
                                  ])),
                                  _: 2
                                }, 1040))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "py-0",
                        title: "Atualizar Status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                            default: _withCtx(() => [
                              _cache[22] || (_cache[22] = _createElementVNode("p", { class: "mb-10" }, " Selecione o novo status para a fatura abaixo. Certifique-se de que a alteração seja apropriada para o estado atual da fatura. ", -1)),
                              _createVNode(_component_SelectInvoiceStatus, {
                                modelValue: _ctx.updateInvoiceForm.status,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.updateInvoiceForm.status) = $event)),
                                currentStatus: _ctx.updateInvoiceForm.status as InvoiceStatus,
                                allStatuses: _ctx.allStatuses,
                                "error-messages": 
                          _ctx.v$.status.$errors
                            ? _ctx.v$.status.$errors[0]?.$message
                            : undefined
                        
                              }, null, 8, ["modelValue", "currentStatus", "allStatuses", "error-messages"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                onClick: ($event: any) => {
                          _ctx.isInvoiceStatusDialogOpen = false;
                          _ctx.updateInvoiceForm.status =
                            item.status as InvoiceStatus;
                        }
                              }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode(" Fechar ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                color: "blue-darken-3",
                                variant: "tonal",
                                onClick: _ctx.updateInvoiceStatus
                              }, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode(" Atualizar ")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]),
                  _createVNode(_component_router_link, {
                    to: `/financeiro/${item.invoiceId}`
                  }, {
                    default: _withCtx(() => [
                      (_ctx.user.type === 'ADMIN' || _ctx.user.type === 'BUSINESS')
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            class: "text-capitalize",
                            variant: "tonal",
                            type: "button"
                          }, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode(" Ver detalhes ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]),
              "no-data": _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode(" Não há nenhuma fatura ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _ctx.handleUpdatePageProps
            }, null, 8, ["total_pages", "total_items", "onUpdate:pagination"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}