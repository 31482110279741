<template>
  <v-text-field
    :label="label"
    :type="type"
    :hide-details="!required"
    :hint="hint"
    :persistent-hint="hint.length > 0"
  >
    <i class="ri-user-3-line" v-if="icon"></i>
  </v-text-field>
</template>

<script lang="ts">
export default {
  name: "TextInput",
  props: {
    label: {
      type: String,
      default: "Escreva",
    },
    type: {
      type: String,
      default: "text",
    },
    hint: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
