import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "pingo-table-content" }
const _hoisted_3 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_4 = { class: "fw-semibold main-color" }
const _hoisted_5 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_6 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_7 = { class: "d-flex justify-content-center align-items-center ga-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Lista de Produtos",
      mainTitle: "Produtos"
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "mb-2" }, "Produtos", -1)),
          _createVNode(_component_router_link, {
            class: "default-btn pingo small",
            to: { name: 'CreateProductPage' }
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
              _createTextVNode(" Criar novo produto ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.products,
              loading: _ctx.isLoadingProducts,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.itemId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(value), 1)
              ]),
              [`item.price`]: _withCtx(({ value }) => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)), 1)
              ]),
              [`item.isActive`]: _withCtx(({ value }) => [
                value
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Ativo "))
                  : _createCommentVNode("", true),
                (!value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Inativo "))
                  : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_btn, {
                    class: "text-capitalize",
                    color: item.isActive ? 'red-darken-3' : 'green-darken-3',
                    variant: "tonal",
                    type: "button",
                    onClick: ($event: any) => (_ctx.handleChangeProductStatus(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.isActive ? "Desativar" : "Ativar"), 1)
                    ]),
                    _: 2
                  }, 1032, ["color", "onClick"]),
                  _createVNode(_component_router_link, {
                    to: `/produtos/editar/${item.itemId}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "text-capitalize",
                        color: "primary-darken-3",
                        variant: "tonal",
                        type: "button"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Editar ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createVNode(_component_v_dialog, {
                    width: "auto",
                    scrollable: ""
                  }, {
                    activator: _withCtx(({ props: activatorProps }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "text-capitalize",
                        color: "red-darken-3",
                        variant: "tonal"
                      }, activatorProps), {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Remover produto ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_v_card, {
                        class: "py-0",
                        title: "Remover produto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" Tem certeza que deseja remover este produto? ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                onClick: ($event: any) => (isActive.value = false)
                              }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" Fechar ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                color: "red-darken-3",
                                variant: "tonal",
                                onClick: ($event: any) => (_ctx.handleRemoveProduct(item.itemId))
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" Remover ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              "no-data": _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Não há nenhum produto ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[0] || (_cache[0] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}