import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ps-0 mt-0" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 pingo-card" }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
        _createElementVNode("h5", { class: "mb-0" }, "Configurações")
      ], -1)),
      _createElementVNode("ul", _hoisted_1, [
        (_ctx.user.type == 'BUSINESS')
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_router_link, { to: "/configuracoes/enderecos" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Endereços ")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/politica-de-privacidade" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Política de Privacidade ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/termos-e-condicoes" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Termos e Condições ")
            ])),
            _: 1
          })
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("form", null, [
        _createElementVNode("div", { class: "mb-20" }, [
          _createElementVNode("h5", { class: "fw-semibold mb-8" }, " TERMO DE USO DO SISTEMA MÃOS QUE NUTREM "),
          _createElementVNode("p", null, "Última atualização: [Data]")
        ]),
        _createElementVNode("h2", { class: "fw-semibold mb-10" }, "Bem-vindo ao Sistema Mãos que Nutrem!"),
        _createElementVNode("p", null, " Este Termo de Uso estabelece as condições para a utilização do sistema desenvolvido pela MÃOS QUE NUTREM LTDA, CNPJ nº 55.263.890/0001-16, que oferece serviços integrados de gestão de pedidos de refeições e fornecimento de alimentos produzidos diretamente por nossa empresa. "),
        _createElementVNode("p", null, " A aceitação deste Termo de Uso é indispensável para acessar e utilizar o sistema e os serviços associados. "),
        _createElementVNode("h3", { class: "fw-semibold mt-20 mb-10" }, "1. OBJETO DO SISTEMA"),
        _createElementVNode("h3", { class: "fw-semibold mt-20 mb-10" }, "Política de Privacidade"),
        _createElementVNode("p", null, " A Mãos Que Nutrem valoriza a privacidade e a proteção dos dados pessoais dos seus usuários, conforme as disposições da Lei Geral de Proteção de Dados Pessoais (LGPD - Lei nº 13.709/2018). Esta Política de Privacidade tem como objetivo esclarecer como coletamos, utilizamos, armazenamos, compartilhamos e protegemos as informações de nossos usuários, bem como os direitos dos mesmos em relação aos seus dados pessoais. "),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "1. Coleta de Dados Pessoais"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "1.1 Dados Coletados:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" Coletamos os seguintes dados pessoais dos usuários durante o uso do sistema, conforme necessário para o funcionamento adequado da plataforma: "),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Dados de Identificação: Nome completo, e-mail, CPF, cargo, e informações relacionadas ao vínculo com a empresa contratante."),
            _createElementVNode("li", null, "Dados de Acesso: Login, senha e informações de autenticação no sistema."),
            _createElementVNode("li", null, "Dados de Solicitação de Refeições: Informações sobre as refeições solicitadas, quantidade, datas, opções de cardápio e preferências alimentares."),
            _createElementVNode("li", null, "Dados de Navegação: Informações sobre a interação dos usuários com a plataforma, como o endereço de IP, dispositivo utilizado, e outras informações de navegação (coletadas automaticamente para melhorar a experiência do usuário e a funcionalidade do sistema).")
          ])
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "1.2 Finalidade da Coleta:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" Os dados coletados são utilizados para as seguintes finalidades: "),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Gerenciar as solicitações de refeições dos funcionários e fornecer os serviços contratados pela Empresa Contratante."),
            _createElementVNode("li", null, "Emitir relatórios sobre o consumo de refeições para as Empresas Contratantes e para os funcionários."),
            _createElementVNode("li", null, "Enviar informações de suporte técnico, atualizações e notificações sobre o sistema."),
            _createElementVNode("li", null, "Garantir a segurança do sistema e o cumprimento das obrigações contratuais e legais.")
          ])
        ]),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "2. Uso de Dados Pessoais"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "2.1 Base Legal para o Tratamento de Dados:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" A Mãos que Nutrem trata os dados pessoais dos usuários com base nas seguintes hipóteses previstas na LGPD: "),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Execução de Contrato: Para fornecer os serviços contratados pela Empresa Contratante e permitir que os funcionários façam suas solicitações de refeições."),
            _createElementVNode("li", null, "Obrigações Legais: Para cumprir obrigações legais ou regulatórias, como emissão de notas fiscais e registros de dados para fins fiscais e tributários."),
            _createElementVNode("li", null, "Legítimo Interesse: Para garantir a segurança do sistema, prevenir fraudes e melhorar os serviços prestados."),
            _createElementVNode("li", null, "Consentimento: Para a coleta e uso de dados sensíveis, como preferências alimentares, caso seja necessário.")
          ])
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "2.2 Compartilhamento de Dados:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" A Mãos que Nutrem não compartilha dados pessoais com terceiros, exceto nas seguintes situações: "),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Com as Empresas Contratantes: Para fornecer relatórios de consumo e para o gerenciamento das solicitações de refeições."),
            _createElementVNode("li", null, "Com prestadores de serviços: Quando necessário para a execução de tarefas específicas, como a hospedagem de dados ou o suporte técnico, sempre que esses prestadores sigam as mesmas obrigações de confidencialidade e proteção de dados."),
            _createElementVNode("li", null, "Por força de lei ou ordem judicial: Quando solicitado por autoridades competentes, conforme exigido por lei ou para a proteção de direitos.")
          ])
        ]),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "3. Armazenamento e Proteção de Dados"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "3.1 Segurança dos Dados:")
        ]),
        _createElementVNode("p", null, " A Mãos que Nutrem adota medidas técnicas e administrativas adequadas para proteger os dados pessoais contra acessos não autorizados, perdas, roubos ou alterações indevidas. Isso inclui o uso de criptografia, firewalls, controles de acesso e outras práticas de segurança da informação. "),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "3.2 Tempo de Retenção dos Dados:")
        ]),
        _createElementVNode("p", null, " Os dados pessoais serão mantidos pelo tempo necessário para cumprir as finalidades para as quais foram coletados, ou conforme exigido por disposições legais, fiscais ou contratuais. Após o término da relação contratual ou solicitação de exclusão dos dados, a Mãos que Nutrem se compromete a excluir todos os dados pessoais de forma segura, exceto aqueles necessários para cumprimento de obrigações legais. "),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "4. Direitos dos Usuários"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "4.1 Direitos de Acesso, Correção e Exclusão:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" Os usuários têm os seguintes direitos em relação aos seus dados pessoais: "),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Acesso: O direito de acessar os dados pessoais que estão sendo processados pela Mãos que Nutrem."),
            _createElementVNode("li", null, "Correção: O direito de corrigir dados incompletos, desatualizados ou incorretos."),
            _createElementVNode("li", null, "Exclusão: O direito de solicitar a exclusão de dados pessoais, exceto quando a retenção for necessária para o cumprimento de obrigações legais ou contratuais."),
            _createElementVNode("li", null, "Portabilidade: O direito de solicitar que os dados sejam transferidos para outra empresa, desde que em formato estruturado e legível."),
            _createElementVNode("li", null, "Revogação do Consentimento: O direito de revogar o consentimento dado para o tratamento de dados pessoais a qualquer momento, sem afetar a legalidade do tratamento realizado até a revogação."),
            _createElementVNode("li", null, "Oposição: O direito de se opor ao tratamento dos dados pessoais, em algumas situações previstas por lei.")
          ])
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "4.2 Como Exercitar Seus Direitos:")
        ]),
        _createElementVNode("p", null, " Os usuários podem exercer seus direitos entrando em contato com a Mãos que Nutrem por meio dos canais de comunicação disponíveis no sistema, solicitando a ação desejada. A empresa se compromete a responder a essas solicitações dentro dos prazos estabelecidos pela LGPD. "),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "5. Alterações nesta Política de Privacidade"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "5.1 Modificações:")
        ]),
        _createElementVNode("p", null, " A Mãos que Nutrem pode alterar esta Política de Privacidade a qualquer momento, para refletir mudanças em suas práticas de privacidade, serviços ou requisitos legais. Quaisquer alterações serão informadas aos usuários por meio de comunicação no sistema, e a versão atualizada será publicada nesta página. O uso contínuo do sistema após qualquer modificação implica na aceitação da nova política. "),
        _createElementVNode("h4", { class: "fw-semibold mt-20 mb-10" }, "6. Contato"),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "6.1 Responsável pelo Tratamento de Dados:")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" Caso os usuários tenham dúvidas ou preocupações em relação ao tratamento de seus dados pessoais ou queiram exercer seus direitos, podem entrar em contato com a Mãos que Nutrem por meio dos seguintes canais: "),
          _createElementVNode("br"),
          _createTextVNode("E-mail: "),
          _createElementVNode("a", {
            class: "p-0",
            href: "mailto:restaurantemaosquenutrem@gmail.com"
          }, "restaurantemaosquenutrem@gmail.com"),
          _createElementVNode("br"),
          _createTextVNode("Telefone: "),
          _createElementVNode("a", { href: "tel:+5544984229264" }, "(44) 98422-9264"),
          _createElementVNode("br"),
          _createTextVNode("Endereço: Av. Centenário, 116 - Vila Christino - Maringá - PR, 87050-040 ")
        ])
      ], -1))
    ]),
    _: 1
  }))
}