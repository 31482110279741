<template>
  <PageTitle pageTitle="Dashboard" mainTitle="Dashboard" />

  <DashboardFilters />

  <div>
    <v-row class="justify-content-center">
      <!-- Total de pedidos -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
                <i :class="stat.icon"></i>
              </div> -->

            <div class="title">
              <h3>{{ orderStats.total }}</h3>

              <!-- <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.total ?? 0)
                }}
              </span> -->

              <span class="gray-color d-block text-caption">
                Pedidos recebidos no geral
              </span>

              <small class="gray-color d-block"> Total de pedidos </small>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Pedidos pendentes -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
              <i :class="stat.icon"></i>
            </div> -->

            <div class="title">
              <h3>{{ orderStats.pending.total }}</h3>

              <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.pending.amount ?? 0)
                }}
              </span>

              <small class="gray-color d-block"> Pedidos pendentes </small>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Pedidos confirmados -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
              <i :class="stat.icon"></i>
            </div> -->

            <div class="title">
              <h3>{{ orderStats.confirmed.total }}</h3>

              <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.confirmed.amount ?? 0)
                }}
              </span>

              <small class="gray-color d-block"> Pedidos confirmados </small>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Pedidos enviados -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
              <i :class="stat.icon"></i>
            </div> -->

            <div class="title">
              <h3>{{ orderStats.shipped.total }}</h3>

              <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.shipped.amount ?? 0)
                }}
              </span>

              <small class="gray-color d-block"> Pedidos enviados </small>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Pedidos entregues -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
              <i :class="stat.icon"></i>
            </div> -->

            <div class="title">
              <h3>{{ orderStats.delivered.total }}</h3>

              <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.delivered.amount ?? 0)
                }}
              </span>

              <small class="gray-color d-block"> Pedidos entregues </small>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Pedidos cancelados -->
      <v-col cols="12" md="6" lg="2" class="col-xxl-2/5 pb-0">
        <v-card class="single-stats-box pingo-card">
          <div class="d-flex align-items-center">
            <!-- <div :class="['icon', stat.class]">
              <i :class="stat.icon"></i>
            </div> -->

            <div class="title">
              <h3>{{ orderStats.cancelled.total }}</h3>

              <span class="gray-color d-block text-caption">
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(orderStats.cancelled.amount ?? 0)
                }}
              </span>

              <small class="gray-color d-block"> Pedidos cancelados </small>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Produtos pendentes -->
      <v-col cols="12" md="6" lg="6" class="pb-0">
        <v-card class="single-stats-box pingo-card mb-25">
          <div class="d-flex align-items-center">
            <div class="title w-100">
              <div class="d-flex justify-space-between">
                <h5 class="mb-2">Produtos pendentes</h5>
              </div>

              <!-- Exibição dos produtos pendente -->
              <template
                v-if="
                  orderStats.pending.items &&
                  orderStats.pending.items.length > 0
                "
              >
                <ul
                  class="d-flex flex-column flex-wrap"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column"
                    v-if="user.type == UserType.ADMIN"
                  >
                    <router-link
                      v-for="item in orderStats.pending.items"
                      :key="item.id"
                      title="Clique para ir para detalhes do produto"
                      :to="{
                        path: `/produtos/editar/${item.id}`,
                      }"
                    >
                      <span class="fw-bold">
                        {{ item.name || "Item sem nome" }}:
                      </span>

                      {{ item.total }} unidade(s)

                      <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
                    </router-link>
                  </div>

                  <div
                    class="d-flex flex-column"
                    v-if="user.type == UserType.BUSINESS"
                  >
                    <li
                      v-for="item in orderStats.delivered.items"
                      :key="item.id"
                    >
                      <span class="fw-bold">
                        {{ item.name || "Item sem nome" }}:
                      </span>

                      {{ item.total }} unidade(s)
                    </li>
                  </div>
                </ul>

                <small>
                  Estes são os produtos pendentes para entrega com suas
                  respectivas quantidades.
                </small>
              </template>

              <!-- Mensagem caso não haja itens pendente -->
              <template v-else>
                <p>Nenhum produto está pendente até o momento.</p>
              </template>
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Produtos entregues -->
      <v-col cols="12" md="6" lg="6" class="pb-0">
        <v-card class="single-stats-box pingo-card mb-25">
          <div class="d-flex align-items-center">
            <div class="title w-100">
              <div class="d-flex justify-space-between">
                <h5 class="mb-2">Produtos entregues</h5>
              </div>

              <!-- Exibição dos produtos entregues -->
              <template
                v-if="
                  orderStats.delivered.items &&
                  orderStats.delivered.items.length > 0
                "
              >
                <ul
                  class="d-flex flex-column flex-wrap"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column"
                    v-if="user.type == UserType.ADMIN"
                  >
                    <router-link
                      v-for="item in orderStats.delivered.items"
                      :key="item.id"
                      title="Clique para ir para detalhes do produto"
                      :to="{
                        path: `/produtos/editar/${item.id}`,
                      }"
                    >
                      <span class="fw-bold">
                        {{ item.name || "Item sem nome" }}:
                      </span>

                      {{ item.total }} unidade(s)

                      <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
                    </router-link>
                  </div>

                  <div
                    class="d-flex flex-column"
                    v-if="user.type == UserType.BUSINESS"
                  >
                    <li
                      v-for="item in orderStats.delivered.items"
                      :key="item.id"
                    >
                      <span class="fw-bold">
                        {{ item.name || "Item sem nome" }}:
                      </span>

                      {{ item.total }} unidade(s)
                    </li>
                  </div>
                </ul>

                <small>
                  Estes são os produtos entregues com suas respectivas
                  quantidades.
                </small>
              </template>

              <!-- Mensagem caso não haja itens entregue -->
              <template v-else>
                <p>Nenhum produto de entregue até o momento.</p>
              </template>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Últimos pedidos</h5>

      <p>Atualizado por último em {{ new Date().toLocaleString() }}</p>
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="orders"
          :loading="isLoadingOrders"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.orderId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.userParent`]="{ item }">
            <router-link
              title="Clique para ir para detalhes da empresa"
              :to="{
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }"
            >
              {{ item.user.userParent.name }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </template>

          <template v-slot:[`item.totalAmount`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span
              v-if="item.status === OrderStatus.Cancelled"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Cancelado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Pending"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              Pendente
            </span>
            <span
              v-else-if="item.status === OrderStatus.Confirmed"
              class="text-capitalize badge rounded-pill text-soft-info"
            >
              Confirmado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Shipped"
              class="text-capitalize badge rounded-pill text-soft-primary"
            >
              Enviado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Delivered"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Entregue
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <router-link :to="`/pedidos/${item.orderId}`">
                <v-btn
                  class="text-capitalize"
                  color="blue-darken-3"
                  variant="tonal"
                  type="button"
                >
                  Ver detalhes
                </v-btn>
              </router-link>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum produto </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onActivated, onMounted, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useDashboardStore } from "@/stores/dashboard.store";

import { useOrder } from "@/composables/useOrder";

import PageTitle from "@/components/Common/PageTitle.vue";
import DashboardFilters from "@/components/Dashboard/Filters/DashboardFilters.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

import { UserType } from "@/types/enum/userType.enum";
import { OrderStatus } from "@/types/enum/orderStatus.enum";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";

export default defineComponent({
  name: "DashboardPage",
  components: {
    PageTitle,
    DashboardFilters,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();
    const dashboardStore = useDashboardStore();

    const { orderStats, getOrderStats } = useOrder();
    const { columns, pagination, orders, getAllOrders } = useOrder();

    const user: Ref = ref(authStore.getUser);

    const isLoadingOrderStats = ref(false);
    const isLoadingOrders = ref(false);

    const getLocalDate = () => {
      return new Date().toISOString().split("T")[0];
    };

    const formatDate = (value: string): string => {
      try {
        // Verifica se o valor está no formato esperado: YYYY-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(value)) {
          throw new Error("Formato de data inválido");
        }

        // Divide o valor em partes (ano, mês, dia)
        const [year, month, day] = value.split("-");

        // Retorna no mesmo formato sem aplicar transformações
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      } catch (error) {
        throw new Error("Formato de data inválido");
      }
    };

    const fetchOrderStatsData = async () => {
      try {
        isLoadingOrderStats.value = true;

        if (user.value.type == UserType.ADMIN) {
          getOrderStats(
            formatDate(getLocalDate()),
            formatDate(getLocalDate())
          ).then(() => {
            isLoadingOrderStats.value = false;
          });
        }

        if (user.value.type == UserType.BUSINESS) {
          getOrderStats(
            formatDate(getLocalDate()),
            formatDate(getLocalDate()),
            user.value.userId
          ).then(() => {
            isLoadingOrderStats.value = false;
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    const fetchOrdersData = async () => {
      try {
        isLoadingOrders.value = true;

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        ).then(() => {
          isLoadingOrders.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchOrdersData();
    };

    watch(
      () => dashboardStore.filters,
      (newFilters) => {
        isLoadingOrderStats.value = true;
        isLoadingOrders.value = true;

        getOrderStats(
          newFilters?.dateStart ? newFilters.dateStart.toString() : undefined,
          newFilters?.dateEnd ? newFilters.dateEnd.toString() : undefined,
          newFilters.userId
        ).then(() => {
          isLoadingOrderStats.value = false;
        });

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage,
          newFilters.userId
        ).then(() => {
          isLoadingOrders.value = false;
        });
      },
      { deep: true }
    );

    onActivated(() => {
      fetchOrderStatsData();
      fetchOrdersData();
    });

    onMounted(() => {
      // dashboardStore.filters.dateStart = formatDate(getLocalDate());
      // dashboardStore.filters.dateEnd = formatDate(getLocalDate());

      fetchOrderStatsData();
      fetchOrdersData();
    });

    return {
      UserType,
      OrderStatus,
      user,
      columns,
      pagination,
      isLoadingOrders,
      isLoadingOrderStats,
      orderStats,
      orders,
      dashboardStore,
      handleUpdatePageProps,
    };
  },
});
</script>
