import { defineStore } from "pinia";

import type { FinancialFiltersRequest } from "@/types/request/filter/financialFilters";

export const useFinancialStore = defineStore("financial", {
  state: () => ({
    filters: {
      userId: undefined,
    } as FinancialFiltersRequest,
  }),
  actions: {
    setFilters(newFilters: FinancialFiltersRequest) {
      this.filters = newFilters;
    },
  },
});
