import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "gray-color d-block text-caption" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "gray-color d-block text-caption" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "gray-color d-block text-caption" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "gray-color d-block text-caption" }
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = { class: "title" }
const _hoisted_17 = { class: "gray-color d-block text-caption" }
const _hoisted_18 = { class: "d-flex align-items-center" }
const _hoisted_19 = { class: "title w-100" }
const _hoisted_20 = {
  class: "d-flex flex-column flex-wrap",
  style: {"list-style":"none"}
}
const _hoisted_21 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_22 = { class: "fw-bold" }
const _hoisted_23 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_24 = { class: "fw-bold" }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = { class: "title w-100" }
const _hoisted_28 = {
  class: "d-flex flex-column flex-wrap",
  style: {"list-style":"none"}
}
const _hoisted_29 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_30 = { class: "fw-bold" }
const _hoisted_31 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_32 = { class: "fw-bold" }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "v-card-header d-flex" }
const _hoisted_35 = { class: "pingo-table-content" }
const _hoisted_36 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_37 = { class: "fw-semibold main-color" }
const _hoisted_38 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_39 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_40 = {
  key: 2,
  class: "text-capitalize badge rounded-pill text-soft-info"
}
const _hoisted_41 = {
  key: 3,
  class: "text-capitalize badge rounded-pill text-soft-primary"
}
const _hoisted_42 = {
  key: 4,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_43 = { class: "d-flex justify-content-center align-items-center ga-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DashboardFilters = _resolveComponent("DashboardFilters")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Dashboard",
      mainTitle: "Dashboard"
    }),
    _createVNode(_component_DashboardFilters),
    _createElementVNode("div", null, [
      _createVNode(_component_v_row, { class: "justify-content-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.total), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "gray-color d-block text-caption" }, " Pedidos recebidos no geral ", -1)),
                      _cache[2] || (_cache[2] = _createElementVNode("small", { class: "gray-color d-block" }, " Total de pedidos ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.pending.total), 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(_ctx.orderStats.pending.amount ?? 0)), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("small", { class: "gray-color d-block" }, " Pedidos pendentes ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.confirmed.total), 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(_ctx.orderStats.confirmed.amount ?? 0)), 1),
                      _cache[4] || (_cache[4] = _createElementVNode("small", { class: "gray-color d-block" }, " Pedidos confirmados ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.shipped.total), 1),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(_ctx.orderStats.shipped.amount ?? 0)), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("small", { class: "gray-color d-block" }, " Pedidos enviados ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.delivered.total), 1),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(_ctx.orderStats.delivered.amount ?? 0)), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("small", { class: "gray-color d-block" }, " Pedidos entregues ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "2",
            class: "col-xxl-2/5 pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.orderStats.cancelled.total), 1),
                      _createElementVNode("span", _hoisted_17, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(_ctx.orderStats.cancelled.amount ?? 0)), 1),
                      _cache[7] || (_cache[7] = _createElementVNode("small", { class: "gray-color d-block" }, " Pedidos cancelados ", -1))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "6",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card mb-25" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "d-flex justify-space-between" }, [
                        _createElementVNode("h5", { class: "mb-2" }, "Produtos pendentes")
                      ], -1)),
                      (
                  _ctx.orderStats.pending.items &&
                  _ctx.orderStats.pending.items.length > 0
                )
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("ul", _hoisted_20, [
                              (_ctx.user.type == _ctx.UserType.ADMIN)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStats.pending.items, (item) => {
                                      return (_openBlock(), _createBlock(_component_router_link, {
                                        key: item.id,
                                        title: "Clique para ir para detalhes do produto",
                                        to: {
                        path: `/produtos/editar/${item.id}`,
                      }
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_22, _toDisplayString(item.name || "Item sem nome") + ": ", 1),
                                          _createTextVNode(" " + _toDisplayString(item.total) + " unidade(s) ", 1),
                                          _createVNode(_component_v_icon, {
                                            icon: "mdi-open-in-new",
                                            size: "x-small"
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.user.type == _ctx.UserType.BUSINESS)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStats.delivered.items, (item) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: item.id
                                      }, [
                                        _createElementVNode("span", _hoisted_24, _toDisplayString(item.name || "Item sem nome") + ": ", 1),
                                        _createTextVNode(" " + _toDisplayString(item.total) + " unidade(s) ", 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _cache[8] || (_cache[8] = _createElementVNode("small", null, " Estes são os produtos pendentes para entrega com suas respectivas quantidades. ", -1))
                          ], 64))
                        : (_openBlock(), _createElementBlock("p", _hoisted_25, "Nenhum produto está pendente até o momento."))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "6",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "single-stats-box pingo-card mb-25" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex justify-space-between" }, [
                        _createElementVNode("h5", { class: "mb-2" }, "Produtos entregues")
                      ], -1)),
                      (
                  _ctx.orderStats.delivered.items &&
                  _ctx.orderStats.delivered.items.length > 0
                )
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("ul", _hoisted_28, [
                              (_ctx.user.type == _ctx.UserType.ADMIN)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStats.delivered.items, (item) => {
                                      return (_openBlock(), _createBlock(_component_router_link, {
                                        key: item.id,
                                        title: "Clique para ir para detalhes do produto",
                                        to: {
                        path: `/produtos/editar/${item.id}`,
                      }
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_30, _toDisplayString(item.name || "Item sem nome") + ": ", 1),
                                          _createTextVNode(" " + _toDisplayString(item.total) + " unidade(s) ", 1),
                                          _createVNode(_component_v_icon, {
                                            icon: "mdi-open-in-new",
                                            size: "x-small"
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.user.type == _ctx.UserType.BUSINESS)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStats.delivered.items, (item) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: item.id
                                      }, [
                                        _createElementVNode("span", _hoisted_32, _toDisplayString(item.name || "Item sem nome") + ": ", 1),
                                        _createTextVNode(" " + _toDisplayString(item.total) + " unidade(s) ", 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _cache[10] || (_cache[10] = _createElementVNode("small", null, " Estes são os produtos entregues com suas respectivas quantidades. ", -1))
                          ], 64))
                        : (_openBlock(), _createElementBlock("p", _hoisted_33, "Nenhum produto de entregue até o momento."))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_34, [
          _cache[12] || (_cache[12] = _createElementVNode("h5", { class: "mb-2" }, "Últimos pedidos", -1)),
          _createElementVNode("p", null, "Atualizado por último em " + _toDisplayString(new Date().toLocaleString()), 1)
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.orders,
              loading: _ctx.isLoadingOrders,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.orderId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_37, _toDisplayString(value), 1)
              ]),
              [`item.userParent`]: _withCtx(({ item }) => [
                _createVNode(_component_router_link, {
                  title: "Clique para ir para detalhes da empresa",
                  to: {
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.user.userParent.name) + " ", 1),
                    _createVNode(_component_v_icon, {
                      icon: "mdi-open-in-new",
                      size: "x-small"
                    })
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              [`item.totalAmount`]: _withCtx(({ value }) => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)), 1)
              ]),
              [`item.status`]: _withCtx(({ item }) => [
                (item.status === _ctx.OrderStatus.Cancelled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, " Cancelado "))
                  : (item.status === _ctx.OrderStatus.Pending)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_39, " Pendente "))
                    : (item.status === _ctx.OrderStatus.Confirmed)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_40, " Confirmado "))
                      : (item.status === _ctx.OrderStatus.Shipped)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_41, " Enviado "))
                        : (item.status === _ctx.OrderStatus.Delivered)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_42, " Entregue "))
                          : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_43, [
                  _createVNode(_component_router_link, {
                    to: `/pedidos/${item.orderId}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "text-capitalize",
                        color: "blue-darken-3",
                        variant: "tonal",
                        type: "button"
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode(" Ver detalhes ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]),
              "no-data": _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Não há nenhum produto ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[0] || (_cache[0] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}