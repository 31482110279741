import { ref } from "vue";

import axios from "@/boot/axios";

import { useAlertStore } from "@/stores/alert.store";

import { AxiosRequestConfig, type AxiosResponse } from "axios";

export const isLoading = ref(false);

export default class BaseService {
  static async get<T>(endpoint: string, params?: object): Promise<T | null> {
    try {
      isLoading.value = true;

      const response = await axios.get(endpoint, { params });
      if (response.status === 200) {
        return response.data || null;
      } else {
        console.error("GET request failed:", response.statusText);
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      useAlertStore().show("error", error.message);

      console.error("Error in GET request:", error);
      return null;
    } finally {
      isLoading.value = false;
    }
  }

  static async post<T>(
    endpoint: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T | boolean | null> {
    try {
      isLoading.value = true;

      const response = await axios.post(endpoint, data, config);

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        if (response.status === 201 && !response.data) return true;

        if (response.data.error === true) return response.data;

        return response.data || true;
      } else {
        console.error("POST request failed:", response.statusText);
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        useAlertStore().show("error", error.response.data.message);
      } else {
        useAlertStore().show(
          "error",
          error.response.data.message ?? error.response.data
        );
      }

      console.error("Error in POST request:", error);
      return null;
    } finally {
      isLoading.value = false;
    }
  }

  static async put<T>(endpoint: string, data?: object): Promise<T | null> {
    try {
      isLoading.value = true;

      const response: AxiosResponse<T> = await axios.put(endpoint, data);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response.data || null;
      } else {
        console.error("PUT request failed:", response.statusText);
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      useAlertStore().show("error", error.message);

      console.error("Error in PUT request:", error);

      return null;
    } finally {
      isLoading.value = false;
    }
  }

  static async delete<T>(endpoint: string): Promise<boolean> {
    try {
      isLoading.value = true;

      const response: AxiosResponse<T> = await axios.delete(endpoint);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return true; // Retorna true em caso de sucesso
      } else {
        console.error("DELETE request failed:", response.statusText);
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      useAlertStore().show("error", error.message);
      console.error("Error in DELETE request:", error);
      return false;
    } finally {
      isLoading.value = false;
    }
  }

  static async patch<T>(endpoint: string, data?: object): Promise<T | null> {
    try {
      isLoading.value = true;

      const response: AxiosResponse<T> = await axios.patch(endpoint, data);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response.data || null;
      } else {
        console.error("PATCH request failed:", response.statusText);
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      useAlertStore().show("error", error.message);

      console.error("Error in PATCH request:", error);

      return null;
    } finally {
      isLoading.value = false;
    }
  }
}
