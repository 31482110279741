import { defineStore } from "pinia";

import type { DashboardFiltersRequest } from "@/types/request/filter/dashboardFilters";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    filters: {
      dateStart: undefined,
      dateEnd: undefined,
      userId: undefined,
    } as DashboardFiltersRequest,
  }),
  actions: {
    setFilters(newFilters: DashboardFiltersRequest) {
      this.filters = newFilters;
    },
  },
});
