import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { PaginationResponse } from "@/types/response/pagination";

import type { AddressResponse } from "@/types/response/address";
import type { CreateAddressRequest } from "@/types/request/create/createAddress";
import type { UpdateAddressRequest } from "@/types/request/update/updateAddress";

export class AddressService {
  static async GetAllAddresses(
    currentPage: number,
    totalPerPage: number,
    userId?: number
  ): Promise<PaginationResponse<AddressResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
        userId,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<
        PaginationResponse<AddressResponse>
      >("address", queryParams);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllAddresses:", error);
      return null;
    }
  }

  static async GetAddressById(id: number): Promise<AddressResponse | null> {
    try {
      const response = await BaseService.get<AddressResponse>(`address/${id}`);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAddressById:", error);
      return null;
    }
  }

  static async CreateAddress(
    payload: CreateAddressRequest
  ): Promise<object | boolean | null> {
    try {
      const response = await BaseService.post<object>("address", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Endereço criado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateAddress:", error);
      return null;
    }
  }

  static async UpdateAddress(
    id: number,
    payload: UpdateAddressRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.patch<object>(`address/${id}`, {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Endereço atualizado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateAddress:", error);
      return null;
    }
  }

  static async DeleteAddressById(id: number): Promise<boolean | null> {
    try {
      const response = await BaseService.delete<boolean>(`address/${id}`);

      if (response) {
        useAlertStore().show("warning", "Endereço removido com sucesso.");
      }

      return response ?? false;
    } catch (error) {
      console.error("Error in DeleteAddressById:", error);
      return false;
    }
  }
}
