<template>
  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card v-if="isLoading" class="pingo-card">
        <div v-for="n in 1" :key="n">
          <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>
      </v-card>

      <v-card v-if="!isLoading" class="pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Informações de Entrega</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-10">{{ userName }}</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li v-if="defaultAddresses" class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Endereço de entrega:</span>
              {{ defaultAddresses.street }}, {{ defaultAddresses.number }},
              {{ defaultAddresses.neighborhood }}, {{ defaultAddresses.city }},
              {{ defaultAddresses.state }}, {{ defaultAddresses.zipCode }},
              {{ defaultAddresses.country }}
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>

    <!-- Uncomment and adjust the following sections as needed
    <v-col cols="12" lg="4" md="12">
      <v-card class="mb-25 pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Billing Information</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-15">{{ user.name }}</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Payment Type :</span>
              Credit Card
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Provider :</span>
              Visa ending in 2851
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">CVV :</span>
              xxx
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" lg="4" md="12">
      <v-card class="mb-25 pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Delivery Info</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-15">UPS Delivery</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Order ID :</span>
              xxxx235
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Payment Mode :</span>
              COD
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Email :</span>
              info@contact.com
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>
    -->
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";

import type { Address } from "@/types/response/orderDetails";

export default defineComponent({
  name: "OrderInformation",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    address: {
      type: Array as PropType<Address[]>,
      required: true,
    },
  },
  setup(props) {
    const defaultAddresses = ref<Address>();

    watch(
      () => props.address,
      (newValue) => {
        defaultAddresses.value = newValue[0];
      }
    );

    return {
      defaultAddresses,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
