<template>
  <v-autocomplete
    label="Selecione uma Empresa"
    :loading="isLoadingBusinessesInfo"
    :item-value="'userId'"
    :item-title="'name'"
    :return-object="returnObject"
    :items="users"
    :clearable="false"
    :hide-details="!required"
    :no-data-text="
      isLoadingBusinessesInfo == true
        ? 'Carregando...'
        : 'Não há nenhum empresa'
    "
    @update:menu="(value) => (isSelectMenuOpen = value)"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <template v-slot:no-data>
      <div class="text-center">
        {{
          isLoadingBusinessesInfo ? "Carregando..." : "Não há nenhuma empresa"
        }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import { useUser } from "@/composables/useUser";
import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "AutoCompleteBusinesses",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  setup() {
    const authStore = useAuthStore();

    const { users, getAllBusinesses, getAllUsers } = useUser();

    const user: Ref = ref(authStore.getUser);

    const isSelectMenuOpen = ref(false);
    const isLoadingBusinessesInfo = ref(false);

    const fetchBusinessesData = async () => {
      try {
        isLoadingBusinessesInfo.value = true;

        if (user.value.type == UserType.ADMIN) {
          getAllUsers(1, 1000, "BUSINESS").then(() => {
            isLoadingBusinessesInfo.value = false;
          });
        }

        if (user.value.type == UserType.BUSINESS) {
          getAllBusinesses(1, 1000).then(() => {
            isLoadingBusinessesInfo.value = false;
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados de empresas:", error);
      }
    };

    watch(
      () => isSelectMenuOpen.value,
      () => {
        fetchBusinessesData();
      },
      {
        once: true,
      }
    );

    return {
      isSelectMenuOpen,
      isLoadingBusinessesInfo,
      users,
    };
  },
});
</script>

<style>
.v-select .v-select__selection-text {
  overflow: hidden !important;
}
</style>
