<template>
  <PageTitle pageTitle="Editar Usuário" mainTitle="Editar Usuário" />

  <v-card class="mb-25 pingo-card">
    <!-- Navigate -->
    <ul class="ps-0 mt-0">
      <li role="button">
        <a
          :class="{ 'router-link-active active': tab === 'personal' }"
          @click="tab = 'personal'"
        >
          Informações Pessoais
        </a>
      </li>
      <li v-if="updateUserForm.type == UserType.BUSINESS" role="button">
        <a
          :class="{ 'router-link-active active': tab === 'address' }"
          @click="tab = 'address'"
        >
          Informações de Endereço
        </a>
      </li>
    </ul>

    <!-- Form: Personal -->
    <v-form v-if="tab == 'personal'" @submit.prevent="handleSubmit()">
      <v-row>
        <v-col lg="12" md="12">
          <div class="v-card-header border-bottom d-flex justify-space-between">
            <h5 class="mb-0">Informações Básicas</h5>

            <div class="pingo-form-group without-icon">
              <v-switch
                color="green-darken-3"
                v-model="updateUserForm.isActive"
                inset
                :hide-details="true"
              >
                <template v-slot:label>
                  <label class="d-block fw-semibold gray-color">
                    {{
                      updateUserForm.isActive == true
                        ? "Usuário ativo"
                        : "Usuário inativo"
                    }}
                  </label>
                </template>
              </v-switch>
            </div>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nome
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o nome do usuário aqui'"
                  :type="'email'"
                  v-model="updateUserForm.name"
                  :error-messages="
                    v$.name.$errors ? v$.name.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o email do usuário aqui'"
                  :type="'email'"
                  v-model="updateUserForm.email"
                  :error-messages="
                    v$.email.$errors ? v$.email.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <!-- <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Documento <span>*</span>
                </label>

                <CpfInput
                  v-if="updateUserForm.type != 'BUSINESS'"
                  :label="'Digite o CPF do usuário aqui'"
                  v-model="updateUserForm.document"
                  :required="true"
                  @update:value="(value: string) => updateUserForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors
                      ? v$.document.$errors[0]?.$message
                      : undefined
                  "
                />

                <CnpjInput
                  v-if="updateUserForm.type == 'BUSINESS'"
                  :label="'Digite o CNPJ do usuário aqui'"
                  v-model="updateUserForm.document"
                  :required="true"
                  @update:value="(value: string) => updateUserForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors
                      ? v$.document.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col> -->

            <!-- <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Celular <span>*</span>
                </label>

                <PhoneInput
                  :label="'Digite o celular do usuário aqui'"
                  :type="'tel'"
                  v-model="updateUserForm.phone"
                  :error-messages="
                    v$.phone.$errors ? v$.phone.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col> -->

            <!-- <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Tipo de usuário
                  <span>*</span>
                </label>

                <v-select
                  label="Selecione um tipo"
                  @update:model-value="(value: 'ADMIN' | 'PARTNER' | 'BUSINESS' | 'COLLABORATOR' | 'BENEFICIARY') => updateUserForm.type = value"
                  :items="[
                    {
                      title: 'Administrador',
                      value: 'ADMIN',
                    },
                    {
                      title: 'Parceiro',
                      value: 'PARTNER',
                    },
                    {
                      title: 'Empresa',
                      value: 'BUSINESS',
                    },
                    {
                      title: 'Colaborador',
                      value: 'COLLABORATOR',
                    },
                    {
                      title: 'Beneficiário',
                      value: 'BENEFICIARY',
                    },
                  ]"
                  v-model="updateUserForm.type"
                  :error-messages="
                    v$.type.$errors ? v$.type.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col> -->

            <v-col
              v-if="
                userData.type == UserType.ADMIN &&
                updateUserForm.type == UserType.BUSINESS
              "
              cols="12"
              lg="6"
              md="6"
            >
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Dia de Cobrança
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o dia de cobrança aqui'"
                  :type="'text'"
                  v-model="updateUserForm.billingDay"
                  @update:value="(value: string) => updateUserForm.billingDay = value"
                  :error-messages="
                    v$.billingDay.$errors
                      ? v$.billingDay.$errors[0]?.$message
                      : undefined
                  "
                  :hint="'Informe os dias de cobrança separados por vírgula (ex: 5, 15, 25). O faturamento será realizado automáticamente nos dias informados.'"
                />
              </div>
            </v-col>

            <!-- <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Senha
                </label>

                <TextInput
                  :label="'Digite a senha do usuário aqui'"
                  :type="'password'"
                  v-model="updateUserForm.password"
                  :error-messages="
                    v$.password.$errors
                      ? v$.password.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirme a sua senha
                </label>

                <TextInput
                  :label="'Digite a senha novamente'"
                  :type="'password'"
                  v-model="updateUserForm.confirmationPassword"
                  :error-messages="
                    v$.confirmationPassword.$errors
                      ? v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <!-- Form: Address -->
    <form v-if="tab == 'address'">
      <div
        class="v-card-header d-flex align-items-center justify-space-between border-bottom"
      >
        <div class="v-card-header mb-0">
          <h5 class="mb-0">Informações de Endereço</h5>

          <p>
            <span v-if="addresses.length == 0">
              Parece que essa empresa não possui nenhum endereço.
            </span>
          </p>

          <p>
            <span v-if="addresses.length > 0">
              Gerencie os endereços da empresa aqui.
            </span>
          </p>
        </div>

        <router-link
          v-if="userData.type == 'ADMIN'"
          :to="{
            name: 'SettingsNewAddressPage',
            params: { userId: updateUserForm.userId },
          }"
        >
          <button
            type="button"
            class="default-btn px-8"
            style="padding: 14px 25px 14px 25px !important"
          >
            Novo endereço
          </button>
        </router-link>
      </div>

      <div class="column mt-20">
        <div
          v-if="isLoadingAdresses"
          class="d-flex flex-column justify-space-center align-items-center text-center w-100 py-15"
        >
          <v-progress-circular
            class="mb-8"
            color="primary"
            indeterminate
          ></v-progress-circular>

          <span class="gray-color fw-medium d-inline-block">Carregando...</span>
        </div>

        <v-row v-if="!isLoadingAdresses">
          <v-col
            v-for="(address, index) in addresses"
            v-bind:key="index"
            cols="12"
            lg="4"
            md="4"
            sm="6"
          >
            <v-card class="pingo-card address-card">
              <div class="v-card-header border-bottom">
                <div
                  class="d-flex justify-space-between align-items-center w-100"
                >
                  <h5 class="mb-0">Endereço #{{ index + 1 }}</h5>

                  <DeleteAddressModal :address-id="address.addressId" />
                </div>
              </div>

              <div class="box">
                <h5 class="fw-semibold mb-15">{{ updateUserForm.name }}</h5>

                <ul class="list ps-0 mb-0 mt-0">
                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">Endereço:</span>
                    {{ address.street }} {{ address.number }},
                    {{ address.neighborhood }} - {{ address.city }},
                    {{ address.state }} {{ address.zipCode }},
                    {{ address.country }}
                  </li>

                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">Complemento:</span>
                    {{ address.complement || "N/A" }}
                  </li>

                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">
                      Endereço padrão:
                    </span>
                    {{ address.isDefault ? "Sim" : "Não" }}
                  </li>
                </ul>

                <v-divider class="my-4"></v-divider>

                <router-link
                  :to="`/configuracoes/endereco/editar/${address.addressId}`"
                >
                  <button type="button" class="default-btn w-100 px-8">
                    Alterar endereço
                  </button>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </form>

    <div v-if="tab == 'personal'" class="text-end">
      <button
        type="button"
        class="default-btn"
        :disabled="isLoadingUserRequest"
        @click="handleSubmitGeneral()"
      >
        <v-progress-circular
          v-if="isLoadingUserRequest"
          indeterminate
          size="18"
          width="2"
        ></v-progress-circular>

        <span v-if="isLoadingUserRequest"> Atualizando Usuário... </span>
        <span v-if="!isLoadingUserRequest"> Atualizar Usuário </span>
      </button>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import EventBus from "@/utils/eventBus";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";
import { useLoadingDialog } from "@/stores/loading.store";

import { useUser } from "@/composables/useUser";
import { useAddress } from "@/composables/useAddress";
import { useViaCEP } from "@/composables/useViaCEP";

import PageTitle from "@/components/Common/PageTitle.vue";

import DeleteAddressModal from "@/components/Settings/Address/DeleteAddressModal.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
// import CpfInput from "@/components/Forms/AdvancedElements/Input/CpfInput.vue";
// import CnpjInput from "@/components/Forms/AdvancedElements/Input/CnpjInput.vue";
// import PhoneInput from "@/components/Forms/AdvancedElements/Input/PhoneInput.vue";

import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "EditUserPage",
  components: {
    PageTitle,
    DeleteAddressModal,
    TextInput,
    // CpfInput,
    // CnpjInput,
    // PhoneInput,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const userData: Ref = ref(authStore.getUser);

    const {
      user,
      updateUserForm,
      updateUserFormRules,
      getUserById,
      getCollaboratorById,
      updateUser,
    } = useUser();

    const { address: viaCEPAddress, getAddress } = useViaCEP();

    const { addresses, getAllAddresses } = useAddress();

    const {
      // address,
      newAddressForm,
      newAddressFormRules,
      // getAddressById,

      createAddress,
    } = useAddress();

    const tab = ref("personal");

    const isLoadingUserInfo = ref(false);
    const isLoadingUserRequest = ref(false);
    const isLoadingAdresses = ref(false);

    // const isLoadingAddressInfo = ref(false);
    const isLoadingAddressRequest = ref(false);

    const fetchUserData = async (user_id: number) => {
      try {
        isLoadingUserInfo.value = true;

        if (userData.value.type == UserType.ADMIN) await getUserById(user_id);
        if (userData.value.type == UserType.BUSINESS)
          await getCollaboratorById(user_id);

        isLoadingUserInfo.value = false;

        updateUserForm.userId = user.value?.userId ?? user_id;
        updateUserForm.name = user.value?.name ?? "";
        updateUserForm.email = user.value?.email ?? "";
        updateUserForm.document = user.value?.document ?? "";
        updateUserForm.phone = user.value?.phone ?? "";
        updateUserForm.isActive = user.value?.isActive;

        const type = route.query.type;
        if (type)
          updateUserForm.type = type.toString() as
            | "ADMIN"
            | "PARTNER"
            | "BUSINESS"
            | "COLLABORATOR"
            | "BENEFICIARY";

        updateUserForm.billingDay = user.value?.billingDay;
      } catch (error) {
        console.error("Erro ao buscar detalhes de usuário:", error);
      }
    };

    const fetchAddressesData = async () => {
      try {
        isLoadingAdresses.value = true;

        if (userData.value.type == UserType.ADMIN) {
          const userId = parseInt(route.params.id.toString());

          getAllAddresses(1, 25, userId).then(() => {
            isLoadingAdresses.value = false;
          });
        } else if (
          userData.value.type == UserType.BUSINESS ||
          updateUserForm.type == UserType.BUSINESS
        ) {
          const userId = parseInt(route.params.id.toString());

          getAllAddresses(1, 25, userId).then(() => {
            isLoadingAdresses.value = false;
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados de endereços:", error);
      }
    };

    const updatePostalCode = (value: string) => {
      if (value.length == 9)
        getAddress(value).then(() => {
          if (viaCEPAddress.value) {
            newAddressForm.street = viaCEPAddress.value.logradouro;
            newAddressForm.complement = viaCEPAddress.value.complemento;
            newAddressForm.neighborhood = viaCEPAddress.value.bairro;
            newAddressForm.city = viaCEPAddress.value.localidade;
            newAddressForm.state = viaCEPAddress.value.uf;
          }
        });
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Atualizando usuário...");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmationPassword, ...updateUserFormPayload } = updateUserForm;

      // if (updateUserFormPayload.billingDay)
      //   updateUserFormPayload.billingDay = updateUserFormPayload.billingDay;

      updateUserFormPayload.userParentId = userData.value.userId;

      if (updateUserFormPayload.document) {
        updateUserFormPayload.document = updateUserFormPayload.document.replace(
          /\D/g,
          ""
        );
      }

      if (updateUserFormPayload.phone) {
        updateUserFormPayload.phone = updateUserFormPayload.phone.replace(
          /\D/g,
          ""
        );
      }

      if (userData.value.type != "ADMIN") {
        delete updateUserFormPayload.billingDay;
        delete updateUserFormPayload.dueDays;
      } else {
        if (updateUserFormPayload.billingDay) {
          updateUserFormPayload.billingDay =
            updateUserFormPayload.billingDay.replace(/\s+/g, "");
        }
      }

      const userId = parseInt(route.params.id.toString());

      const response = await updateUser(userId, updateUserFormPayload);

      loadingStore.setLoadingOff();

      if (response) {
        setTimeout(() => {
          router.go(-1);
        }, 2000);
      }
    };

    const handleSubmitAddress = async () => {
      newAddressForm.country = "Brasil";

      const result = await v$address.value.$validate();

      if (!result) return;

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      newAddressForm.userId = parseInt(route.params.id.toString());

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...newAddressFormPayload } = newAddressForm;

      const response = await createAddress(newAddressFormPayload);

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      if (response)
        setTimeout(() => {
          router.go(-1);
        }, 2000);
    };

    const handleSubmitGeneral = async () => {
      if (tab.value === "personal") {
        await handleSubmit();
      } else if (tab.value === "address") {
        await handleSubmitAddress();
      }
    };

    onMounted(async () => {
      const userId = parseInt(route.params.id.toString());
      await fetchUserData(userId);

      if (updateUserForm.type != UserType.COLLABORATOR)
        await fetchAddressesData();

      // const addressId = parseInt(route.params.id.toString());
      // fetchAddressData(addressId);

      // Listen for the address-update event
      EventBus.on("address-update", async () => {
        if (updateUserForm.type != UserType.COLLABORATOR)
          await fetchAddressesData();
      });
    });

    const v$ = useVuelidate(updateUserFormRules, updateUserForm);
    const v$address = useVuelidate(newAddressFormRules, newAddressForm);

    return {
      UserType,
      v$,
      v$address,
      route,
      router,
      userData,
      tab,
      addresses,
      isLoadingUserInfo,
      isLoadingUserRequest,
      isLoadingAdresses,
      newAddressForm,
      updatePostalCode,
      updateUserForm,
      handleSubmit,
      handleSubmitAddress,
      handleSubmitGeneral,
    };
  },
});
</script>

<style lang="scss" scoped>
.pingo-form-group {
  margin-bottom: 0px;
}

.box {
  h5 {
    font-size: 16px;
  }
  .list {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.address-card {
  border: 1px solid #ccc !important;
}

.v-input__details {
  display: none !important;
}
button {
  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
ul {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    display: inline-block;
    margin: {
      right: 15px;
      bottom: 15px;
    }
    a {
      color: var(--paragraphColor);
      background-color: #f4f4f4;
      display: inline-block;
      border-radius: 5px;
      padding: 10px 25px;
      font: {
        size: var(--fontSize);
        weight: 600;
      }
      &:hover,
      &.active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  ul {
    li {
      a {
        font-size: 14px;
      }
    }
  }

  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
