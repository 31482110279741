import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import SignInPage from "@/pages/Auth/SignInPage.vue";
import SignUpPage from "@/pages/Auth/SignUpPage.vue";
import ForgotPasswordPage from "@/pages/Auth/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/pages/Auth/ResetPasswordPage.vue";
import PasswordCodePage from "@/pages/Auth/PasswordCodePage.vue";

import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";

import BeneficiariesPage from "@/pages/User/Beneficiaries/BeneficiariesPage.vue";
import CollaboratorsPage from "@/pages/User/Collaborators/CollaboratorsPage.vue";
import BusinessPage from "@/pages/User/Business/BusinessPage.vue";

import NewUserPage from "@/pages/User/NewUserPage.vue";
import NewBusinessPage from "@/pages/User/Business/NewBusinessPage.vue";
import EditUserPage from "@/pages/User/EditUserPage.vue";

import ProductsPage from "@/pages/Product/ProductsPage.vue";
import CreateProductPage from "@/pages/Product/CreateProductPage.vue";
import EditProductPage from "@/pages/Product/EditProductPage.vue";
import ProductsDetailsPage from "@/pages/Product/ProductDetailsPage.vue";

import FinancialPage from "@/pages/Financial/FinancialPage.vue";
import FinancialDetailsPage from "@/pages/Financial/FinancialDetailsPage.vue";

import OrdersPage from "@/pages/Order/OrdersPage.vue";
import CreateOrderPage from "@/pages/Order/CreateOrderPage.vue";
import CreateOrderBatchPage from "@/pages/Order/CreateOrderBatchPage.vue";
import EditOrderPage from "@/pages/Order/EditOrderPage.vue";

import OrderDetailsPage from "@/pages/Order/OrderDetailsPage.vue";

import AddressPage from "@/pages/Settings/Address/AddressPage.vue";
import NewAddressPage from "@/pages/Settings/Address/NewAddressPage.vue";
import EditAddressPage from "@/pages/Settings/Address/EditAddressPage.vue";

import AccountPage from "@/pages/Settings/AccountPage.vue";
import PrivacyPolicyPage from "@/pages/Settings/PrivacyPolicyPage.vue";
import TermsConditionsPage from "@/pages/Settings/TermsConditionsPage.vue";

import ErrorPage from "@/pages/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/autenticar/acessar",
    name: "SignInPage",
    component: SignInPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/autenticar/criar-conta",
    name: "SignUpPage",
    component: SignUpPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/autenticar/esqueci-minha-senha",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    // path: "/autenticar/confirmar-codigo&:token&:email",
    path: "/autenticar/confirmar-codigo",
    name: "PasswordCodePage",
    component: PasswordCodePage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/autenticar/resetar-senha",
    name: "ResetPasswordPage",
    component: ResetPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "DashboardPage",
    component: DashboardPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR", "ATTENDANT"],
    },
  },
  {
    path: "/usuarios/empresas",
    name: "BusinessPage",
    component: BusinessPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/usuarios/colaboradores",
    name: "CollaboratorsPage",
    component: CollaboratorsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS"],
    },
  },
  {
    path: "/usuarios/beneficiarios",
    name: "BeneficiariesPage",
    component: BeneficiariesPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "PARTNER"],
    },
  },
  {
    path: "/usuarios/novo",
    name: "NewUserPage",
    component: NewUserPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS"],
    },
  },
  {
    path: "/usuarios/nova-empresa",
    name: "NewUserBusinessPage",
    component: NewBusinessPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/usuario/editar/:id",
    name: "EditUserPage",
    component: EditUserPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS"],
    },
  },

  {
    path: "/configuracoes/conta",
    name: "SettingsAccountPage",
    component: AccountPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR"],
    },
  },
  {
    path: "/configuracoes/enderecos",
    name: "SettingsAddressPage",
    component: AddressPage,
    meta: {
      requiresAuth: true,
      roles: ["BUSINESS"],
    },
  },
  {
    path: "/configuracoes/endereco/novo/:userId?",
    name: "SettingsNewAddressPage",
    component: NewAddressPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/configuracoes/endereco/editar/:id",
    name: "SettingsEditAddressPage",
    component: EditAddressPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/configuracoes/politica-de-privacidade",
    name: "SettingsPrivacyPolicyPage",
    component: PrivacyPolicyPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR"],
    },
  },
  {
    path: "/configuracoes/termos-e-condicoes",
    name: "SettingsTermsConditionsPage",
    component: TermsConditionsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR"],
    },
  },
  {
    path: "/pedidos",
    name: "OrderPage",
    component: OrdersPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR", "ATTENDANT"],
    },
  },
  {
    path: "/pedidos/:id",
    name: "OrderDetailsPage",
    component: OrderDetailsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR", "ATTENDANT"],
    },
  },
  {
    path: "/pedidos/novo",
    name: "CreateOrderPage",
    component: CreateOrderPage,
    meta: {
      requiresAuth: true,
      roles: ["BUSINESS", "ATTENDANT", "COLLABORATOR"],
    },
  },
  {
    path: "/pedidos-em-massa/novo",
    name: "CreateOrderBatchPage",
    component: CreateOrderBatchPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR", "ATTENDANT"],
    },
  },
  {
    path: "/pedidos/editar/:id",
    name: "EditOrderPage",
    component: EditOrderPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "ATTENDANT"],
    },
  },
  {
    path: "/financeiro",
    name: "FinancialPage",
    component: FinancialPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS", "COLLABORATOR"],
    },
  },
  {
    path: "/financeiro/:id",
    name: "FinancialDetailsPage",
    component: FinancialDetailsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN", "BUSINESS"],
    },
  },
  {
    path: "/produtos",
    name: "ProductsPage",
    component: ProductsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/produto/:id",
    name: "ProductsDetailsPage",
    component: ProductsDetailsPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/produtos/novo",
    name: "CreateProductPage",
    component: CreateProductPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  {
    path: "/produtos/editar/:id",
    name: "EditProductPage",
    component: EditProductPage,
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"],
    },
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      // const tokenDecoded = jwtDecode(token, {
      //   header: false,
      // });
      // const isExpired =
      //   !!tokenDecoded.exp && Date.now() > tokenDecoded.exp * 1000;
      // if (isExpired) {
      //   useAuthStore().setLogout();
      //   router.push({ path: "/autenticar/acessar" }).then(() => {
      //     router.go(0);
      //   });
      // }

      const userDecoded = JSON.parse(user);
      const userRole = userDecoded.type;

      if (Array.isArray(to.meta.roles) && !to.meta.roles.includes(userRole))
        router.go(-1);
      else next();

      // next();
    } else {
      router.push({ path: "/autenticar/acessar" }).then(() => {
        router.go(0);
      });

      next();
    }
  } else {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (to.name === "SignInPage" && token && user) router.push({ path: "/" });

    // Non-protected route, allow access
    next();
  }
});

export default router;
