<template>
  <h4 class="mb-20 mt-25">Sumário de Pedidos</h4>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="fw-semibold gray-color text-start">Pedido N.º</th>
          <th class="fw-semibold gray-color text-start">Empresa</th>
          <th class="fw-semibold gray-color text-start">Colaborador</th>
          <th class="fw-semibold gray-color">Criado Em</th>
          <th class="fw-semibold gray-color">Última Atualização Em</th>
          <th class="fw-semibold gray-color">Status</th>
          <th class="fw-semibold gray-color">Valor</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="order in orders" :key="order.orderId">
          <!-- <td class="main-color fw-semibold">{{ index + 1 }}.</td> -->
          <td class="text-start fw-semibold">
            <router-link
              title="Clique para ir para detalhes do pedido"
              :to="`/pedidos/${order.orderId}`"
            >
              Order #{{ order.orderId }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </td>

          <!-- <td class="text-start fw-semibold">
            <router-link
              title="Clique para ir para detalhes do pedido"
              :to="`/pedidos/${order.orderId}`"
            >
              Order #{{ order.user.name }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </td>

          <td class="text-start fw-semibold">
            <router-link
              title="Clique para ir para detalhes do pedido"
              :to="`/pedidos/${order.orderId}`"
            >
              Order #{{ order.orderId }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </td> -->

          <td class="gray-color">
            <router-link
              title="Clique para ir para detalhes da empresa"
              :to="{
                path: `/usuario/editar/${order.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }"
            >
              {{ order.user.userParent.name }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </td>

          <td class="gray-color">{{ order.user.name }}</td>

          <td class="gray-color">
            {{ new Date(order.createdAt).toLocaleDateString() }}
          </td>

          <td class="gray-color">
            {{ new Date(order.updatedAt).toLocaleDateString() }}
          </td>

          <td class="gray-color">
            <span
              v-if="order.status === 'cancelled'"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Cancelado
            </span>
            <span
              v-else-if="order.status === 'pending'"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              Pendente
            </span>
            <span
              v-else-if="order.status === 'confirmed'"
              class="text-capitalize badge rounded-pill text-soft-info"
            >
              Confirmado
            </span>
            <span
              v-else-if="order.status === 'shipped'"
              class="text-capitalize badge rounded-pill text-soft-primary"
            >
              Enviado
            </span>
            <span
              v-else-if="order.status === 'delivered'"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Entregue
            </span>
          </td>
          <td class="gray-color text-end">
            {{ formatCurrency(parseFloat(order.totalAmount)) }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="gray-color fw-semibold">Subtotal:</td>
          <td class="gray-color fw-semibold text-end">
            {{ formatCurrency(subtotal) }}
          </td>
        </tr>

        <tr class="total">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="fw-semibold">Total:</td>
          <td class="fw-semibold text-end">
            {{ formatCurrency(subtotal) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";

// import { jsPDF } from "jspdf";

export default defineComponent({
  name: "OrderSummary",
  components: {},
  props: {
    orders: {
      type: Array as PropType<
        Array<{
          orderId: number;
          totalAmount: string;
          status: string;
          createdAt: string;
          updatedAt: string;
          user: {
            name: string;
            userId: number;
            userParent: {
              name: string;
              userId: number;
            };
          };
        }>
      >,
      required: true,
    },
  },
  setup(props) {
    const subtotal = computed(() =>
      props.orders.reduce(
        (total, order) => total + parseFloat(order.totalAmount),
        0
      )
    );

    const formatCurrency = (value: number) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    };

    return {
      subtotal,
      formatCurrency,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid var(--whiteColor);
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
}
table,
.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: #f7faff;
        vertical-align: middle;
        white-space: nowrap;
        text-align: center;
        position: relative;
        padding: 15px 20px;

        &::after {
          top: 50%;
          right: 0;
          width: 1px;
          content: "";
          height: 17px;
          position: absolute;
          background-color: #e7ebf5;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 15px 20px;
        text-align: center;
        white-space: nowrap;
        border-bottom-color: #f7faff;
        border-bottom: 1px solid #f7faff;

        .product-info {
          .v-img {
            width: 48px;
            margin-right: 12px;
            border-radius: 8px;
          }
        }
        .btn-box {
          button,
          a {
            padding: 10px 18px 11px !important;
            font: {
              weight: 600 !important;
              size: 14px !important;
            }
          }
          a {
            margin-right: 8px;
          }
          button {
            margin-left: 8px;
          }
        }
      }
      &.total {
        td {
          font-size: 18px;
        }
      }
    }
  }
}
.btn-box {
  button,
  a {
    border-radius: 5px !important;

    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
    }
    &.gray {
      background-color: #edeff5;
      color: var(--blackColor);
    }
  }
}
</style>
