<template>
  <PageTitle pageTitle="Detalhes de Pedido" mainTitle="Pedidos" />

  <v-row class="justify-content-center">
    <v-col cols="12" lg="4" md="12">
      <TrackOrder :is-loading="isLoadingOrderInfo" :order="order" />
    </v-col>

    <v-col cols="12" lg="8" md="12">
      <v-alert
        v-if="
          order.status != OrderStatus.Cancelled &&
          order.status != OrderStatus.Delivered &&
          isDifferentDay
        "
        color="red-lighten-1"
        type="warning"
        class="text-start mb-10"
      >
        Pedido agendado para
        <strong> {{ formatDate(order.dateReference.toString()) }} </strong>.
      </v-alert>

      <v-alert
        v-if="order.status == OrderStatus.Cancelled"
        color="red-lighten-1"
        type="warning"
        class="text-start mb-10"
      >
        Pedido cancelado em
        <strong>
          {{
            new Date(order.updatedAt).toLocaleDateString("pt-BR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }} </strong
        >.
      </v-alert>

      <OrderInformation
        :is-loading="isLoadingOrderInfo"
        :userName="order.user.name"
        :address="order.address"
      />

      <v-form>
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <v-card class="create-project-card pingo-card">
              <div class="v-card-header border-bottom">
                <div class="d-flex flex-column align-items-start">
                  <h5 class="mb-0">Informações do Pedido</h5>

                  <span class="gray-color">
                    Pedido criado em
                    {{
                      new Intl.DateTimeFormat("pt-BR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(order.createdAt))
                    }}
                  </span>
                </div>

                <div
                  v-if="
                    order.status !== OrderStatus.Delivered &&
                    order.status !== OrderStatus.Cancelled
                  "
                >
                  <v-dialog width="380" scrollable v-model="isDialogOpen">
                    <template v-slot:activator="{ props: activatorProps }">
                      <div class="d-flex ga-2">
                        <v-dialog
                          v-if="
                            user.type == UserType.ADMIN ||
                            user.type == UserType.BUSINESS ||
                            user.type == UserType.PARTNER
                          "
                          max-width="480"
                          scrollable
                        >
                          <template
                            v-slot:activator="{ props: activatorProps }"
                          >
                            <v-btn
                              v-bind="activatorProps"
                              class="text-capitalize"
                              color="red-darken-3"
                              variant="tonal"
                              type="button"
                            >
                              Cancelar pedido
                            </v-btn>
                          </template>

                          <template v-slot:default="{ isActive }">
                            <v-form
                              @submit.prevent="
                                handleUpdateOrderAsCancelled(order.orderId)
                              "
                            >
                              <v-card
                                class="py-0"
                                title="Cancelar pedido em andamento"
                              >
                                <v-divider></v-divider>

                                <v-card-text class="px-4">
                                  Tem certeza que deseja cancelar este pedido?
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-btn
                                    class="text-capitalize"
                                    @click="isActive.value = false"
                                  >
                                    Fechar
                                  </v-btn>

                                  <v-spacer></v-spacer>

                                  <v-btn
                                    color="red-darken-3"
                                    variant="tonal"
                                    type="submit"
                                    style="text-transform: none"
                                  >
                                    Sim, quero cancelar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-form>
                          </template>
                        </v-dialog>

                        <v-btn
                          v-if="
                            user.type == UserType.ADMIN ||
                            user.type == UserType.ATTENDANT
                          "
                          class="text-capitalize"
                          color="blue-darken-3"
                          variant="tonal"
                          v-bind="activatorProps"
                        >
                          Atualizar status
                        </v-btn>

                        <PrinterComponent
                          v-if="
                            user.type == UserType.ADMIN ||
                            user.type == UserType.ATTENDANT
                          "
                          :order="order"
                        />
                      </div>
                    </template>

                    <template v-slot:default="">
                      <v-form @submit.prevent="updateOrderStatus()">
                        <v-card class="py-0" title="Atualizar Status">
                          <v-divider></v-divider>

                          <v-card-text class="px-4">
                            <p class="mb-10">
                              Selecione o novo status para o pedido abaixo.
                              Certifique-se de que a alteração seja apropriada
                              para o estado atual da pedido.
                            </p>

                            <SelectOrderStatus
                              :currentStatus="order.status as OrderStatus"
                              :allStatuses="filteredStatuses as OrderStatus[]"
                              @update:value="
                                (value) => (updateOrderForm.status = value)
                              "
                              :required="true"
                              :error-messages="
                                v$.status.$errors
                                  ? v$.status.$errors[0]?.$message
                                  : undefined
                              "
                            />
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              class="text-capitalize"
                              @click="isDialogOpen = false"
                            >
                              Fechar
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                              class="text-capitalize"
                              color="blue-darken-3"
                              variant="tonal"
                              type="submit"
                            >
                              Atualizar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </template>
                  </v-dialog>
                </div>
              </div>

              <v-data-table
                class="table"
                :headers="newOrderColumns"
                :items="order.items"
                hide-default-footer
              >
                <template v-slot:[`item.name`]="{ item }">
                  <a class="d-flex align-items-center product-info py-2">
                    <v-img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/1022px-Placeholder_view_vector.svg.png?20220519031949"
                      class="border-radius me-12"
                      width="60"
                      alt="product"
                      style="min-width: 60px"
                    />

                    <div class="title">
                      <h5 class="fw-semibold mb-1">
                        {{ item.item?.name ?? "Desconhecido" }}
                      </h5>

                      <span class="d-block muted-color fw-medium">
                        Descrição:
                        <span class="gray-color">
                          {{ item.item?.description ?? "Não informado" }}
                        </span>
                      </span>
                    </div>
                  </a>
                </template>

                <template v-slot:[`item.price`]="{ item }">
                  {{
                    new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(parseFloat(item.unitPrice) ?? 0)
                  }}
                </template>

                <template v-slot:[`item.observation`]="{ item }">
                  <span
                    v-if="item.observations"
                    class="text-capitalize badge rounded-pill text-soft-danger"
                  >
                    {{ item.observations }}
                  </span>

                  <span
                    v-if="!item.observations"
                    class="text-capitalize badge rounded-pill text-soft-warning"
                  >
                    N/A
                  </span>
                </template>

                <template v-slot:[`item.total`]="{ item }">
                  {{
                    new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format((parseFloat(item.unitPrice) ?? 0) * item.quantity)
                  }}
                </template>

                <template v-slot:no-data> Não há nenhum produto </template>

                <template v-slot:loading>
                  <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
              </v-data-table>

              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <!-- Summary -->
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-end fw-semibold gray-color">
                        Sub Total:
                      </td>
                      <td class="fw-semibold text-end">
                        {{
                          new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(subtotal)
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-end fw-semibold gray-color">Desconto:</td>
                      <td class="fw-semibold text-end">
                        {{
                          new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(discount)
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-end fw-semibold gray-color">
                        Taxa de Entrega:
                      </td>
                      <td class="fw-semibold text-end">
                        {{
                          new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(deliveryFee)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-end fw-semibold">Total (BRL):</td>
                      <td class="fw-semibold text-end">
                        {{
                          new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(total)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref } from "vue";

import { useVuelidate } from "@vuelidate/core";

import { useRoute, useRouter } from "vue-router";

import { useAuthStore } from "@/stores/auth.store";

import { useOrder } from "@/composables/useOrder";

import PageTitle from "@/components/Common/PageTitle.vue";

import TrackOrder from "@/components/eCommerce/OrderDetails/TrackOrder.vue";
import PrinterComponent from "@/components/Forms/AdvancedElements/Print/PrinterComponent.vue";
import OrderInformation from "@/components/eCommerce/OrderDetails/OrderInformation.vue";

import SelectOrderStatus from "@/components/Forms/AdvancedElements/Select/SelectOrderStatus.vue";

import { OrderStatus } from "@/types/enum/orderStatus.enum";

import { UserType } from "@/types/enum/userType.enum";

import type { OrderDetailsResponse } from "@/types/response/orderDetails";

export default defineComponent({
  name: "OrderDetailsPage",
  components: {
    PageTitle,
    TrackOrder,
    PrinterComponent,
    OrderInformation,
    SelectOrderStatus,
  },
  setup() {
    const authStore = useAuthStore();
    const route = useRoute();
    const router = useRouter();

    const {
      newOrderColumns,
      updateOrderFormRules,
      updateOrderForm,
      order,
      getOrderById,
      updateOrder,
    } = useOrder();

    const user: Ref = ref(authStore.getUser);

    const isDialogOpen = ref<boolean>(false);
    const isLoadingOrderInfo = ref<boolean>(false);

    const isDifferentDay = computed(() => {
      const today = new Date();
      const dateReference = new Date(order.value.dateReference);
      return (
        dateReference.getDate() !== today.getDate() ||
        dateReference.getMonth() !== today.getMonth() ||
        dateReference.getFullYear() !== today.getFullYear()
      );
    });

    const subtotal = computed(() => {
      return order.value.items.reduce((acc, item) => {
        return acc + parseFloat(item.unitPrice) * item.quantity;
      }, 0);
    });

    const discount = computed(() => 0);
    const deliveryFee = computed(() => 0);
    const total = computed(
      () => subtotal.value - discount.value + deliveryFee.value
    );

    const allStatuses = ref<OrderStatus[]>([
      OrderStatus.Pending,
      OrderStatus.Confirmed,
      OrderStatus.Shipped,
      OrderStatus.Delivered,
      OrderStatus.Cancelled,
    ]);

    const filteredStatuses = computed(() => {
      return allStatuses.value.filter((status) => {
        return isTransitionAllowed(order.value.status, status);
      });
    });

    const formatDate = (value: string): string => {
      try {
        // Verifica se o valor está no formato esperado: YYYY-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(value)) {
          throw new Error("Formato de data inválido");
        }

        // Divide o valor em partes (ano, mês, dia)
        const [year, month, day] = value.split("-");

        // Retorna no mesmo formato sem aplicar transformações
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
      } catch (error) {
        throw new Error("Formato de data inválido");
      }
    };

    const fetchOrderData = async (order_id: number) => {
      try {
        isLoadingOrderInfo.value = true;

        await getOrderById(order_id);

        isLoadingOrderInfo.value = false;
      } catch (error) {
        console.error("Erro ao buscar detalhes de pedido:", error);
      }
    };

    const isTransitionAllowed = (
      current: OrderDetailsResponse["status"],
      next: OrderDetailsResponse["status"]
    ): boolean => {
      if (current === OrderStatus.Pending && next === OrderStatus.Delivered)
        return false;
      if (next === OrderStatus.Delivered && current !== OrderStatus.Shipped)
        return false;

      switch (current) {
        case OrderStatus.Pending:
          return [
            OrderStatus.Confirmed,
            OrderStatus.Shipped,
            OrderStatus.Delivered,
            OrderStatus.Cancelled,
          ].includes(next as OrderStatus);
        case OrderStatus.Confirmed:
          return [
            OrderStatus.Shipped,
            OrderStatus.Delivered,
            OrderStatus.Cancelled,
          ].includes(next as OrderStatus);
        case OrderStatus.Shipped:
          return [OrderStatus.Delivered, OrderStatus.Cancelled].includes(
            next as OrderStatus
          );
        case OrderStatus.Delivered:
          return false;
        default:
          return false;
      }
    };

    const updateOrderStatus = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const updatedOrderPayload = { status: updateOrderForm.status };

      const response = await updateOrder(
        parseInt(route.params.id.toString()),
        updatedOrderPayload
      );

      if (response) router.push({ name: "OrderPage" });
    };

    const handleUpdateOrderAsCancelled = async (orderId: number) => {
      if (!orderId) throw new Error("orderId não encontrado.");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const updatedOrderPayload = { status: OrderStatus.Cancelled };

      const response = await updateOrder(orderId, updatedOrderPayload);

      if (response) if (response) router.push({ name: "OrderPage" });
    };

    const v$ = useVuelidate(updateOrderFormRules, updateOrderForm);

    onMounted(() => {
      const orderId = parseInt(route.params.id.toString());
      fetchOrderData(orderId);
    });

    return {
      UserType,
      v$,
      formatDate,
      order,
      OrderStatus,
      isLoadingOrderInfo,
      newOrderColumns,
      subtotal,
      discount,
      deliveryFee,
      total,
      user,
      updateOrderForm,
      isDialogOpen,
      isDifferentDay,
      filteredStatuses,
      updateOrderStatus,
      handleUpdateOrderAsCancelled,
    };
  },
});
</script>

<style lang="scss" scoped>
.pingo-form-group {
  margin-bottom: 0px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid var(--whiteColor);
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
}
table,
.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: #f7faff;
        vertical-align: middle;
        white-space: nowrap;
        text-align: center;
        position: relative;
        padding: 18px 20px;

        &::after {
          top: 50%;
          right: 0;
          width: 1px;
          content: "";
          height: 17px;
          position: absolute;
          background-color: #e7ebf5;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        padding: 18px 20px;
        white-space: nowrap;
        border-bottom-color: #f7faff;
        border-bottom: 1px solid #f7faff;

        .product-info {
          padding-right: 25px;

          h5 {
            font-size: 15px;
          }
          span {
            font-size: 14px;
          }
        }
        .rating {
          i {
            line-height: 1;
            color: #ffbc2b;
            margin: {
              left: 2px;
              right: 2px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px;

          .product-info {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 20px;
        }
      }
    }
  }
}

.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
