<template>
  <form class="mb-6" @submit.prevent="handleFilter()">
    <v-row>
      <!-- Filters -->
      <v-col class="py-0" cols="12" lg="12" md="12">
        <v-card class="single-stats-box pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="4">
              <div class="pingo-form-group without-icon">
                <AutoCompleteBusinesses
                  v-model="userId"
                  @update:value="(value: UserResponse) => (updateUserId(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3" class="mt-auto mb-auto">
              <v-btn class="w-100" type="submit" size="large" variant="outlined">
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useFinancialStore } from "@/stores/financial.store";

import AutoCompleteBusinesses from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteBusinesses.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserResponse } from "@/types/response/user";

export default defineComponent({
  name: "FinancialFilters",
  components: {
    AutoCompleteBusinesses,
  },
  setup() {
    const authStore = useAuthStore();
    const financialStore = useFinancialStore();

    const user: Ref = ref(authStore.getUser);
    const userId = ref(financialStore.filters.userId);

    const updateUserId = (value: UserResponse) => (userId.value = value.userId);

    const handleFilter = () => {
      financialStore.setFilters({
        userId: userId.value,
      });
    };

    watch(
      () => financialStore.filters,
      (newFilters) => {
        userId.value = newFilters.userId;
      }
    );

    return {
      user,
      userId,
      handleFilter,
      updateUserId,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
