<template>
  <v-autocomplete
    label="Selecione um Produto"
    :loading="isLoadingProductsInfo"
    :item-value="'userId'"
    :item-title="'name'"
    :return-object="true"
    :items="products"
    :clearable="false"
    :hide-details="!required"
    :no-data-text="
      isLoadingProductsInfo == true ? 'Carregando...' : 'Não há nenhum produto'
    "
    @update:menu="(value) => (isSelectMenuOpen = value)"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-bind="props"
        :subtitle="item.raw.description ?? 'Não possui descrição'"
      ></v-list-item>
    </template>

    <template v-slot:no-data>
      <div class="text-center">
        {{ isLoadingProductsInfo ? "Carregando..." : "Não há nenhum produto" }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useProduct } from "@/composables/useProduct";

export default defineComponent({
  name: "AutoCompleteProducts",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  setup() {
    const { products, getAllProducts } = useProduct();

    const isSelectMenuOpen = ref(false);
    const isLoadingProductsInfo = ref(false);

    const fetchProductsData = async () => {
      try {
        isLoadingProductsInfo.value = true;

        getAllProducts(1, 1000).then(() => {
          products.value = products.value.filter(
            (product) => product.isActive == 1
          );

          isLoadingProductsInfo.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de produtos:", error);
      }
    };

    watch(
      () => isSelectMenuOpen.value,
      () => {
        fetchProductsData();
      },
      {
        once: true,
      }
    );

    return {
      isSelectMenuOpen,
      isLoadingProductsInfo,
      products,
    };
  },
});
</script>

<style>
.v-select .v-select__selection-text {
  overflow: hidden !important;
}
</style>
