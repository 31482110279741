import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_5 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_6 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { class: "text-end" }
const _hoisted_10 = {
  type: "submit",
  class: "default-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CpfInput = _resolveComponent("CpfInput")!
  const _component_CnpjInput = _resolveComponent("CnpjInput")!
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_AutoCompleteBusinesses = _resolveComponent("AutoCompleteBusinesses")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Novo Usuário",
      mainTitle: "Novo Usuário"
    }),
    _createVNode(_component_v_form, {
      onSubmit: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              lg: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "create-project-card pingo-card" }, {
                  default: _withCtx(() => [
                    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                      _createElementVNode("h5", { class: "mb-0" }, "Informações Básicas")
                    ], -1)),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Nome "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite o nome do usuário aqui',
                                type: 'email',
                                modelValue: _ctx.newUserForm.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUserForm.name) = $event)),
                                "error-messages": 
                    _ctx.v$.name.$errors ? _ctx.v$.name.$errors[0]?.$message : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Email "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite o email do usuário aqui',
                                type: 'email',
                                modelValue: _ctx.newUserForm.email,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUserForm.email) = $event)),
                                "error-messages": 
                    _ctx.v$.email.$errors ? _ctx.v$.email.$errors[0]?.$message : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _cache[15] || (_cache[15] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Documento "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              (_ctx.newUserForm.type != 'BUSINESS')
                                ? (_openBlock(), _createBlock(_component_CpfInput, {
                                    key: 0,
                                    label: 'Digite o CPF do usuário aqui',
                                    modelValue: _ctx.newUserForm.document,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUserForm.document) = $event)),
                                    required: true,
                                    "onUpdate:value": _cache[3] || (_cache[3] = (value) => _ctx.newUserForm.document = value.replaceAll('.','').replace('-','')),
                                    "error-messages": 
                    _ctx.v$.document.$errors
                      ? _ctx.v$.document.$errors[0]?.$message
                      : undefined
                  
                                  }, null, 8, ["modelValue", "error-messages"]))
                                : _createCommentVNode("", true),
                              (_ctx.newUserForm.type == 'BUSINESS')
                                ? (_openBlock(), _createBlock(_component_CnpjInput, {
                                    key: 1,
                                    label: 'Digite o CNPJ do usuário aqui',
                                    modelValue: _ctx.newUserForm.document,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUserForm.document) = $event)),
                                    required: true,
                                    "onUpdate:value": _cache[5] || (_cache[5] = (value) => _ctx.newUserForm.document = value.replaceAll('.','').replace('-','')),
                                    "error-messages": 
                    _ctx.v$.document.$errors
                      ? _ctx.v$.document.$errors[0]?.$message
                      : undefined
                  
                                  }, null, 8, ["modelValue", "error-messages"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Celular "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_PhoneInput, {
                                label: 'Digite o celular do usuário aqui',
                                type: 'tel',
                                modelValue: _ctx.newUserForm.phone,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newUserForm.phone) = $event)),
                                "error-messages": 
                    _ctx.v$.phone.$errors ? _ctx.v$.phone.$errors[0]?.$message : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        (_ctx.user.type == 'ADMIN')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12",
                              lg: "6",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_5, [
                                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                    _createTextVNode(" Tipo de usuário "),
                                    _createElementVNode("span", null, "*")
                                  ], -1)),
                                  _createVNode(_component_v_select, {
                                    label: "Selecione um tipo",
                                    "onUpdate:modelValue": [
                                      _cache[7] || (_cache[7] = (value) => _ctx.newUserForm.type = value),
                                      _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUserForm.type) = $event))
                                    ],
                                    items: [
                    {
                      title: 'Administrador',
                      value: 'ADMIN',
                    },
                    {
                      title: 'Parceiro',
                      value: 'PARTNER',
                    },
                    {
                      title: 'Empresa',
                      value: 'BUSINESS',
                    },
                    {
                      title: 'Colaborador',
                      value: 'COLLABORATOR',
                    },
                    {
                      title: 'Beneficiário',
                      value: 'BENEFICIARY',
                    },
                  ],
                                    modelValue: _ctx.newUserForm.type,
                                    "error-messages": 
                    _ctx.v$.type.$errors ? _ctx.v$.type.$errors[0]?.$message : undefined
                  
                                  }, null, 8, ["modelValue", "error-messages"])
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
                _ctx.newUserForm.type == _ctx.UserType.COLLABORATOR &&
                _ctx.user.type == _ctx.UserType.ADMIN
              )
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 1,
                              cols: "12",
                              lg: "6",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                    _createTextVNode(" Selecione uma empresa "),
                                    _createElementVNode("span", null, "*")
                                  ], -1)),
                                  _createVNode(_component_AutoCompleteBusinesses, {
                                    "onUpdate:value": _cache[9] || (_cache[9] = (value) => _ctx.newUserForm.userParentId = value.userId),
                                    required: true,
                                    "error-messages": 
                    _ctx.v$.userParentId.$errors
                      ? _ctx.v$.userParentId.$errors[0]?.$message
                      : undefined
                  
                                  }, null, 8, ["error-messages"])
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Senha "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite a senha do usuário aqui',
                                type: 'password',
                                modelValue: _ctx.newUserForm.password,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newUserForm.password) = $event)),
                                "error-messages": 
                    _ctx.v$.password.$errors
                      ? _ctx.v$.password.$errors[0]?.$message
                      : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Confirme a sua senha "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite a senha novamente',
                                type: 'password',
                                modelValue: _ctx.newUserForm.confirmationPassword,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newUserForm.confirmationPassword) = $event)),
                                "error-messages": 
                    _ctx.v$.confirmationPassword.$errors
                      ? _ctx.v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", _hoisted_10, [
                        (_ctx.isLoadingUserRequest)
                          ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                              key: 0,
                              indeterminate: "",
                              size: "18",
                              width: "2"
                            }))
                          : _createCommentVNode("", true),
                        _cache[21] || (_cache[21] = _createTextVNode(" Criar Usuário "))
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}