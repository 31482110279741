import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, {
    label: "Selecione uma Empresa",
    loading: _ctx.isLoadingBusinessesInfo,
    "item-value": 'userId',
    "item-title": 'name',
    "return-object": _ctx.returnObject,
    items: _ctx.users,
    clearable: false,
    "hide-details": !_ctx.required,
    "no-data-text": 
      _ctx.isLoadingBusinessesInfo == true
        ? 'Carregando...'
        : 'Não há nenhum empresa'
    ,
    "onUpdate:menu": _cache[0] || (_cache[0] = (value) => (_ctx.isSelectMenuOpen = value)),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => _ctx.$emit('update:value', value))
  }, {
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.isLoadingBusinessesInfo ? "Carregando..." : "Não há nenhuma empresa"), 1)
    ]),
    _: 1
  }, 8, ["loading", "return-object", "items", "hide-details", "no-data-text"]))
}