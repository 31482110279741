import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-card-header border-bottom" }
const _hoisted_2 = { class: "d-flex justify-space-between ga-2" }
const _hoisted_3 = { class: "text-h5" }
const _hoisted_4 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "d-flex align-items-center justify-space-between" }
const _hoisted_7 = { class: "d-flex ga-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "pingo-form-group without-icon" }
const _hoisted_11 = { class: "pingo-form-group without-icon" }
const _hoisted_12 = {
  key: 1,
  border: "left"
}
const _hoisted_13 = {
  class: "d-flex justify-space-between ga-2",
  style: {"max-width":"150px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteCollaborators = _resolveComponent("AutoCompleteCollaborators")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_AutoCompleteProducts = _resolveComponent("AutoCompleteProducts")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Novo Pedido em Massa",
      mainTitle: "Pedido"
    }),
    _createVNode(_component_v_form, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              lg: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "create-project-card pingo-card" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _cache[18] || (_cache[18] = _createElementVNode("h5", { class: "mb-0" }, "Informações do Pedido em Massa", -1)),
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_v_dialog, {
                          modelValue: _ctx.dialog,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog) = $event)),
                          "max-width": "600px"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_btn, _mergeProps({
                              class: "text-capitalize",
                              color: "blue-darken-3",
                              variant: "tonal"
                            }, props), {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Adicionar ")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formTitle), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_4, [
                                              _cache[7] || (_cache[7] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                _createTextVNode(" Selecione um colaborador "),
                                                _createElementVNode("span", null, "*")
                                              ], -1)),
                                              _createVNode(_component_AutoCompleteCollaborators, {
                                                modelValue: _ctx.editedItem.user,
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedItem.user) = $event)),
                                                "onUpdate:value": _cache[1] || (_cache[1] = (value) => _ctx.editedItem.user = value),
                                                required: false
                                              }, null, 8, ["modelValue"])
                                            ])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_5, [
                                              _cache[8] || (_cache[8] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Agendado para ", -1)),
                                              _createVNode(_component_TextInput, {
                                                modelValue: _ctx.editedItem.dateReference,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editedItem.dateReference) = $event)),
                                                type: 'date',
                                                label: 'Digite aqui',
                                                required: false
                                              }, null, 8, ["modelValue"])
                                            ])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_6, [
                                              _cache[10] || (_cache[10] = _createElementVNode("label", { class: "d-block mb-0 fw-semibold gray-color" }, " Produtos ", -1)),
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                color: "blue-darken-3",
                                                variant: "tonal",
                                                onClick: _ctx.addItem
                                              }, {
                                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                  _createTextVNode(" Adicionar ")
                                                ])),
                                                _: 1
                                              }, 8, ["onClick"])
                                            ]),
                                            (_ctx.editedItem.items.length > 0)
                                              ? (_openBlock(), _createBlock(_component_v_expansion_panels, { key: 0 }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editedItem.items, (item, index) => {
                                                      return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                                                        class: "mt-4",
                                                        key: index
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_expansion_panel_title, null, {
                                                            actions: _withCtx(({ expanded }) => [
                                                              _createElementVNode("div", _hoisted_7, [
                                                                (item.item)
                                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(
                                        item.item?.price * item.quantity
                                      )), 1))
                                                                  : _createCommentVNode("", true),
                                                                _createVNode(_component_v_icon, {
                                                                  color: !expanded ? 'teal' : '',
                                                                  icon: expanded ? 'mdi-minus' : 'mdi-plus'
                                                                }, null, 8, ["color", "icon"]),
                                                                _createVNode(_component_v_icon, {
                                                                  color: "red",
                                                                  onClick: ($event: any) => (_ctx.deleteProductItem(index))
                                                                }, {
                                                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                                    _createTextVNode(" mdi-delete ")
                                                                  ])),
                                                                  _: 2
                                                                }, 1032, ["onClick"])
                                                              ])
                                                            ]),
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString("Produto #" + (index + 1)) + " ", 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          _createVNode(_component_v_expansion_panel_text, null, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_col, { cols: "12" }, {
                                                                default: _withCtx(() => [
                                                                  _createElementVNode("div", _hoisted_9, [
                                                                    _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                                      _createTextVNode(" Selecione um produto "),
                                                                      _createElementVNode("span", null, "*")
                                                                    ], -1)),
                                                                    _createVNode(_component_AutoCompleteProducts, {
                                                                      modelValue: item.item,
                                                                      "onUpdate:modelValue": ($event: any) => ((item.item) = $event),
                                                                      "onUpdate:value": (value) => item.item = value,
                                                                      required: false
                                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate:value"])
                                                                  ])
                                                                ]),
                                                                _: 2
                                                              }, 1024),
                                                              _createVNode(_component_v_col, { cols: "12" }, {
                                                                default: _withCtx(() => [
                                                                  _createElementVNode("div", _hoisted_10, [
                                                                    _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                                      _createTextVNode(" Quantidade "),
                                                                      _createElementVNode("span", null, "*")
                                                                    ], -1)),
                                                                    _createVNode(_component_TextInput, {
                                                                      modelValue: item.quantity,
                                                                      "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                                                      type: "number",
                                                                      label: 'Digite aqui',
                                                                      required: false
                                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                                  ])
                                                                ]),
                                                                _: 2
                                                              }, 1024),
                                                              _createVNode(_component_v_col, { cols: "12" }, {
                                                                default: _withCtx(() => [
                                                                  _createElementVNode("div", _hoisted_11, [
                                                                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Observação ", -1)),
                                                                    _createVNode(_component_TextInput, {
                                                                      modelValue: item.observation,
                                                                      "onUpdate:modelValue": ($event: any) => ((item.observation) = $event),
                                                                      label: 'Digite aqui',
                                                                      required: false
                                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                                  ])
                                                                ]),
                                                                _: 2
                                                              }, 1024)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    }), 128))
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (_ctx.editedItem.items.length === 0)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, " Não há nenhum produto adicionado. "))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_actions, null, {
                                  default: _withCtx(() => [
                                    (_ctx.error)
                                      ? (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 0,
                                          type: "error"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.error), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_v_btn, {
                                      color: "blue-darken-1",
                                      variant: "text",
                                      onClick: _ctx.close
                                    }, {
                                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                                        _createTextVNode(" Cancelar ")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]),
                                    _createVNode(_component_v_btn, {
                                      color: "blue-darken-1",
                                      variant: "text",
                                      onClick: _ctx.save
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode(" Salvar ")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createVNode(_component_v_btn, {
                          class: "text-capitalize",
                          color: "green-darken-3",
                          variant: "tonal",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSubmit())),
                          disabled: _ctx.isLoadingOrderRequest || _ctx.rows.length === 0
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isLoadingOrderRequest)
                              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                                  key: 0,
                                  indeterminate: "",
                                  size: "18",
                                  width: "2"
                                }))
                              : _createCommentVNode("", true),
                            _cache[17] || (_cache[17] = _createTextVNode(" Finalizar "))
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ])
                    ]),
                    _createVNode(_component_v_data_table, {
                      class: "table",
                      expanded: _ctx.expanded,
                      "onUpdate:expanded": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.expanded) = $event)),
                      headers: _ctx.headers,
                      items: _ctx.rows,
                      "item-value": "userId",
                      "hide-default-footer": ""
                    }, {
                      [`item.id`]: _withCtx(({ index }) => [
                        _createTextVNode(_toDisplayString(index + 1), 1)
                      ]),
                      [`item.collaborator`]: _withCtx(({ value }) => [
                        _createTextVNode(_toDisplayString(value ?? "-"), 1)
                      ]),
                      [`item.dateReference`]: _withCtx(({ value }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(value)), 1)
                      ]),
                      [`item.items`]: _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(item.items.map((i) => i.item?.name).join(", ")), 1)
                      ]),
                      [`item.totalAmount`]: _withCtx(({ value }) => [
                        _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(value)), 1)
                      ]),
                      [`item.actions`]: _withCtx(({ item }) => [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize",
                            color: "primary-darken-3",
                            variant: "tonal",
                            onClick: ($event: any) => (_ctx.editItem(item))
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" Editar ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize",
                            color: "red-darken-3",
                            variant: "tonal",
                            onClick: ($event: any) => (_ctx.deleteItem(item))
                          }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode(" Remover ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      "no-data": _withCtx(() => _cache[21] || (_cache[21] = [
                        _createTextVNode(" Não há nenhum pedido ")
                      ])),
                      _: 2
                    }, 1032, ["expanded", "headers", "items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}