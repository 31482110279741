import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "pingo-table-content" }
const _hoisted_3 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_4 = { class: "fw-semibold main-color" }
const _hoisted_5 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_6 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_7 = { class: "d-flex justify-content-center align-items-center ga-6" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "pingo-form-group without-icon" }
const _hoisted_10 = { class: "pingo-form-group without-icon" }
const _hoisted_11 = { class: "pingo-form-group without-icon" }
const _hoisted_12 = { class: "pingo-form-group without-icon" }
const _hoisted_13 = { class: "pingo-form-group without-icon" }
const _hoisted_14 = { class: "pingo-form-group without-icon" }
const _hoisted_15 = { class: "pingo-form-group without-icon" }
const _hoisted_16 = { class: "pingo-form-group without-icon mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PostalCodeInput = _resolveComponent("PostalCodeInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Empresas",
      mainTitle: "Empresas"
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[13] || (_cache[13] = _createElementVNode("h5", { class: "mb-2" }, "Lista de Empresas", -1)),
          _createVNode(_component_router_link, {
            class: "default-btn pingo small",
            to: { name: 'NewUserBusinessPage', query: { type: 'BUSINESS' } }
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
              _createTextVNode(" Criar nova empresa ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.users,
              loading: _ctx.isLoadingUserBusiness,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.userId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(value), 1)
              ]),
              [`item.isActive`]: _withCtx(({ value }) => [
                (value == true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Ativo "))
                  : _createCommentVNode("", true),
                (value == false)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Inativo "))
                  : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.user.type == 'ADMIN')
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'BUSINESS' },
                }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize",
                            color: "primary-darken-3",
                            variant: "tonal"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Editar ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_dialog, {
                    width: "auto",
                    scrollable: ""
                  }, {
                    activator: _withCtx(({ props: activatorProps }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "text-capitalize",
                        color: "red-darken-3",
                        variant: "tonal"
                      }, activatorProps), {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Remover ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_v_card, {
                        class: "py-0",
                        title: "Remover empresa"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Tem certeza que deseja remover esta empresa? ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                onClick: ($event: any) => (isActive.value = false)
                              }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode(" Fechar ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                color: "red-darken-3",
                                variant: "tonal",
                                onClick: ($event: any) => (_ctx.handleRemoveBusiness(item.userId))
                              }, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode(" Remover ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.user.type == 'ADMIN')
                    ? (_openBlock(), _createBlock(_component_v_menu, { key: 1 }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[19] || (_cache[19] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, {
                            class: "dotMenu monthMenu",
                            style: {"width":"250px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.isAddressModalOpen,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isAddressModalOpen) = $event)),
                                "max-width": "700",
                                scrollable: ""
                              }, {
                                activator: _withCtx(({ props: activatorProps }) => [
                                  _createElementVNode("button", _mergeProps(activatorProps, {
                                    type: "button",
                                    class: "bg-transparent w-100 border-none",
                                    onClick: ($event: any) => (_ctx.newAddressForm.userId = item.userId)
                                  }), " Adicionar endereço ", 16, _hoisted_8)
                                ]),
                                default: _withCtx(({ isActive }) => [
                                  _createVNode(_component_v_form, {
                                    onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.handleCreateAddress()), ["prevent"]))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, {
                                        class: "py-0",
                                        title: "Adicionar endereço"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_9, [
                                                        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" CEP "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_PostalCodeInput, {
                                                          label: 'Digite o seu CEP aqui',
                                                          modelValue: _ctx.newAddressForm.zipCode,
                                                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAddressForm.zipCode) = $event)),
                                                          "onUpdate:value": _cache[1] || (_cache[1] = (value) => _ctx.updatePostalCode(value)),
                                                          "error-messages": 
                                      _ctx.v$.zipCode.$errors
                                        ? _ctx.v$.zipCode.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_10, [
                                                        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Rua "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Nome da rua',
                                                          modelValue: _ctx.newAddressForm.street,
                                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAddressForm.street) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.street.$errors
                                        ? _ctx.v$.street.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_11, [
                                                        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Bairro "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite o bairro aqui',
                                                          modelValue: _ctx.newAddressForm.neighborhood,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAddressForm.neighborhood) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.neighborhood.$errors
                                        ? _ctx.v$.neighborhood.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_12, [
                                                        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Cidade "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite a cidade aqui',
                                                          modelValue: _ctx.newAddressForm.city,
                                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAddressForm.city) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.city.$errors
                                        ? _ctx.v$.city.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_13, [
                                                        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Estado "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite o estado aqui',
                                                          modelValue: _ctx.newAddressForm.state,
                                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAddressForm.state) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.state.$errors
                                        ? _ctx.v$.state.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_14, [
                                                        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Número "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite o número aqui',
                                                          modelValue: _ctx.newAddressForm.number,
                                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newAddressForm.number) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.number.$errors
                                        ? _ctx.v$.number.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_15, [
                                                        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Complemento ", -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite o complemento aqui (opcional)',
                                                          modelValue: _ctx.newAddressForm.complement,
                                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newAddressForm.complement) = $event))
                                                        }, null, 8, ["modelValue"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_16, [
                                                        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                                          _createTextVNode(" Endereço Principal? "),
                                                          _createElementVNode("span", null, "*")
                                                        ], -1)),
                                                        _createVNode(_component_v_select, {
                                                          modelValue: _ctx.newAddressForm.isDefault,
                                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newAddressForm.isDefault) = $event)),
                                                          items: [
                                      { title: 'Sim', value: true },
                                      { title: 'Não', value: false },
                                    ],
                                                          "error-messages": 
                                      _ctx.v$.isDefault.$errors
                                        ? _ctx.v$.isDefault.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_actions, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                onClick: ($event: any) => (isActive.value = false)
                                              }, {
                                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                                  _createTextVNode(" Fechar ")
                                                ])),
                                                _: 2
                                              }, 1032, ["onClick"]),
                                              _createVNode(_component_v_spacer),
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                color: "green-darken-3",
                                                variant: "tonal",
                                                type: "submit"
                                              }, {
                                                default: _withCtx(() => _cache[29] || (_cache[29] = [
                                                  _createTextVNode(" Confirmar ")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ]),
              "no-data": _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode(" Não há nenhuma empresa ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[11] || (_cache[11] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}