import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex ga-2"
}
const _hoisted_3 = { class: "pingo-table-content" }
const _hoisted_4 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_5 = { class: "fw-semibold main-color" }
const _hoisted_6 = {
  key: 1,
  class: "fw-semibold"
}
const _hoisted_7 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_8 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_9 = {
  key: 2,
  class: "text-capitalize badge rounded-pill text-soft-info"
}
const _hoisted_10 = {
  key: 3,
  class: "text-capitalize badge rounded-pill text-soft-primary"
}
const _hoisted_11 = {
  key: 4,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_12 = { class: "d-flex justify-content-center align-items-center ga-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_OrderFilters = _resolveComponent("OrderFilters")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Lista de Pedidos",
      mainTitle: "Pedidos"
    }),
    (
      _ctx.user.type == _ctx.UserType.ADMIN ||
      _ctx.user.type == _ctx.UserType.BUSINESS ||
      _ctx.user.type == _ctx.UserType.ATTENDANT
    )
      ? (_openBlock(), _createBlock(_component_OrderFilters, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("h5", { class: "mb-2" }, "Pedidos", -1)),
          (
          _ctx.user.type == _ctx.UserType.COLLABORATOR ||
          _ctx.user.type == _ctx.UserType.BUSINESS ||
          _ctx.user.type == _ctx.UserType.ATTENDANT
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_router_link, {
                  class: "default-btn pingo small",
                  to: { name: 'CreateOrderPage' }
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                    _createTextVNode(" Criar novo pedido ")
                  ])),
                  _: 1
                }),
                (_ctx.user.type == _ctx.UserType.ADMIN || _ctx.user.type == _ctx.UserType.BUSINESS)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "default-btn pingo small",
                      to: { name: 'CreateOrderBatchPage' }
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                        _createTextVNode(" Criar pedidos em massa ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.orders,
              loading: _ctx.isLoadingOrders,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.orderId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(value), 1)
              ]),
              [`item.userParent`]: _withCtx(({ item }) => [
                (_ctx.user.type != _ctx.UserType.COLLABORATOR)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      title: "Clique para ir para detalhes da empresa",
                      to: {
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.user.userParent.name) + " ", 1),
                        _createVNode(_component_v_icon, {
                          icon: "mdi-open-in-new",
                          size: "x-small"
                        })
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.user.userParent.name), 1))
              ]),
              [`item.totalAmount`]: _withCtx(({ value }) => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)), 1)
              ]),
              [`item.status`]: _withCtx(({ item }) => [
                (item.status === _ctx.OrderStatus.Cancelled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Cancelado "))
                  : (item.status === _ctx.OrderStatus.Pending)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Pendente "))
                    : (item.status === _ctx.OrderStatus.Confirmed)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Confirmado "))
                      : (item.status === _ctx.OrderStatus.Shipped)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Enviado "))
                        : (item.status === _ctx.OrderStatus.Delivered)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Entregue "))
                          : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    to: `/pedidos/${item.orderId}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "text-capitalize",
                        color: "blue-darken-3",
                        variant: "tonal",
                        type: "button"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Ver detalhes ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  (
                  _ctx.user.type == _ctx.UserType.ADMIN ||
                  _ctx.user.type == _ctx.UserType.BUSINESS ||
                  _ctx.user.type == _ctx.UserType.PARTNER
                )
                    ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, {
                            class: "dotMenu monthMenu",
                            style: {"width":"250px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.isCancelOrderModalOpen,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isCancelOrderModalOpen) = $event)),
                                "max-width": "480",
                                scrollable: ""
                              }, {
                                activator: _withCtx(({ props: activatorProps }) => [
                                  _createElementVNode("button", _mergeProps(activatorProps, {
                                    type: "button",
                                    class: "bg-transparent w-100 border-none"
                                  }), " Cancelar pedido ", 16)
                                ]),
                                default: _withCtx(({ isActive }) => [
                                  _createVNode(_component_v_form, {
                                    onSubmit: _withModifiers(($event: any) => (_ctx.handleUpdateOrderAsCancelled(item)), ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, {
                                        class: "py-0",
                                        title: "Cancelar pedido em andamento"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                              _createTextVNode(" Tem certeza que deseja cancelar este pedido? ")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_actions, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                onClick: ($event: any) => (isActive.value = false)
                                              }, {
                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                  _createTextVNode(" Fechar ")
                                                ])),
                                                _: 2
                                              }, 1032, ["onClick"]),
                                              _createVNode(_component_v_spacer),
                                              _createVNode(_component_v_btn, {
                                                color: "red-darken-3",
                                                variant: "tonal",
                                                type: "submit",
                                                style: {"text-transform":"none"}
                                              }, {
                                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                  _createTextVNode(" Sim, quero cancelar ")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onSubmit"])
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ]),
              "no-data": _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Não há nenhum produto ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[1] || (_cache[1] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}