import { defineStore } from "pinia";

import type { OrderFiltersRequest } from "@/types/request/filter/orderFilters";

export const useOrderStore = defineStore("order", {
  state: () => ({
    filters: {
      userId: undefined,
    } as OrderFiltersRequest,
  }),
  actions: {
    setFilters(newFilters: OrderFiltersRequest) {
      this.filters = newFilters;
    },
  },
});
