<template>
  <PageTitle pageTitle="Empresas" mainTitle="Empresas" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Lista de Empresas</h5>

      <router-link
        class="default-btn pingo small"
        :to="{ name: 'NewUserBusinessPage', query: { type: 'BUSINESS' } }"
      >
        <i class="ri-add-fill"></i>
        Criar nova empresa
      </router-link>

      <!-- <p>Atualizado por último em {{ new Date().toLocaleString() }}</p> -->
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="users"
          :loading="isLoadingUserBusiness"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.userId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.isActive`]="{ value }">
            <span
              v-if="value == true"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Ativo
            </span>
            <span
              v-if="value == false"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Inativo
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <router-link
                v-if="user.type == 'ADMIN'"
                :to="{
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'BUSINESS' },
                }"
              >
                <v-btn
                  class="text-capitalize"
                  color="primary-darken-3"
                  variant="tonal"
                >
                  Editar
                </v-btn>
              </router-link>

              <v-dialog width="auto" scrollable>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="text-capitalize"
                    color="red-darken-3"
                    variant="tonal"
                    v-bind="activatorProps"
                  >
                    Remover
                  </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card class="py-0" title="Remover empresa">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      Tem certeza que deseja remover esta empresa?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="isActive.value = false"
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="red-darken-3"
                        variant="tonal"
                        @click="handleRemoveBusiness(item.userId)"
                      >
                        Remover
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <v-menu v-if="user.type == 'ADMIN'">
                <template v-slot:activator="{ props }">
                  <button
                    type="button"
                    v-bind="props"
                    class="position-relative dot-btn p-0 bg-transparent border-none"
                  >
                    <i class="ri-more-fill"></i>
                  </button>
                </template>

                <v-list class="dotMenu monthMenu" style="width: 250px">
                  <v-dialog
                    v-model="isAddressModalOpen"
                    max-width="700"
                    scrollable
                  >
                    <template v-slot:activator="{ props: activatorProps }">
                      <button
                        v-bind="activatorProps"
                        type="button"
                        class="bg-transparent w-100 border-none"
                        @click="newAddressForm.userId = item.userId"
                      >
                        Adicionar endereço
                      </button>
                    </template>

                    <template v-slot:default="{ isActive }">
                      <v-form @submit.prevent="handleCreateAddress()">
                        <v-card class="py-0" title="Adicionar endereço">
                          <v-divider></v-divider>

                          <v-card-text class="px-4">
                            <v-row>
                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    CEP
                                    <span>*</span>
                                  </label>

                                  <PostalCodeInput
                                    :label="'Digite o seu CEP aqui'"
                                    v-model="newAddressForm.zipCode"
                                    @update:value="(value: string) => updatePostalCode(value)"
                                    :error-messages="
                                      v$.zipCode.$errors
                                        ? v$.zipCode.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Rua
                                    <span>*</span>
                                  </label>
                                  <TextInput
                                    :label="'Nome da rua'"
                                    v-model="newAddressForm.street"
                                    :error-messages="
                                      v$.street.$errors
                                        ? v$.street.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Bairro
                                    <span>*</span>
                                  </label>
                                  <TextInput
                                    :label="'Digite o bairro aqui'"
                                    v-model="newAddressForm.neighborhood"
                                    :error-messages="
                                      v$.neighborhood.$errors
                                        ? v$.neighborhood.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Cidade
                                    <span>*</span>
                                  </label>
                                  <TextInput
                                    :label="'Digite a cidade aqui'"
                                    v-model="newAddressForm.city"
                                    :error-messages="
                                      v$.city.$errors
                                        ? v$.city.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Estado
                                    <span>*</span>
                                  </label>
                                  <TextInput
                                    :label="'Digite o estado aqui'"
                                    v-model="newAddressForm.state"
                                    :error-messages="
                                      v$.state.$errors
                                        ? v$.state.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Número
                                    <span>*</span>
                                  </label>
                                  <TextInput
                                    :label="'Digite o número aqui'"
                                    v-model="newAddressForm.number"
                                    :error-messages="
                                      v$.number.$errors
                                        ? v$.number.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Complemento
                                  </label>
                                  <TextInput
                                    :label="'Digite o complemento aqui (opcional)'"
                                    v-model="newAddressForm.complement"
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12" lg="6" md="6">
                                <div class="pingo-form-group without-icon mb-0">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Endereço Principal?
                                    <span>*</span>
                                  </label>
                                  <v-select
                                    v-model="newAddressForm.isDefault"
                                    :items="[
                                      { title: 'Sim', value: true },
                                      { title: 'Não', value: false },
                                    ]"
                                    :error-messages="
                                      v$.isDefault.$errors
                                        ? v$.isDefault.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              class="text-capitalize"
                              @click="isActive.value = false"
                            >
                              Fechar
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                              class="text-capitalize"
                              color="green-darken-3"
                              variant="tonal"
                              type="submit"
                            >
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </template>
                  </v-dialog>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhuma empresa </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useVuelidate } from "@vuelidate/core";

import { useLoadingDialog } from "@/stores/loading.store";

import { useAddress } from "@/composables/useAddress";
import { useViaCEP } from "@/composables/useViaCEP";

import { useAuthStore } from "@/stores/auth.store";

import { useUser } from "@/composables/useUser";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import PostalCodeInput from "@/components/Forms/AdvancedElements/Input/PostalCodeInput.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";
import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "BusinessPage",
  components: {
    PageTitle,
    TextInput,
    PostalCodeInput,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const { address, getAddress } = useViaCEP();

    const { newAddressForm, newAddressFormRules, createAddress } = useAddress();

    const {
      columns,
      pagination,
      users,
      getAllUsers,
      getAllBusinesses,
      deleteUserById,
    } = useUser();

    const user: Ref = ref(authStore.getUser);

    const isLoadingUserBusiness = ref(false);
    const isLoadingAddressRequest = ref(false);

    const isAddressModalOpen = ref(false);

    const fetchAllUsersData = async () => {
      try {
        isLoadingUserBusiness.value = true;

        getAllUsers(
          pagination.value.currentPage ?? 1,
          pagination.value.totalPerPage ?? 10,
          UserType.BUSINESS
        ).then(() => {
          isLoadingUserBusiness.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de empresas:", error);
      }
    };

    const fetchUserBusinessData = async () => {
      try {
        isLoadingUserBusiness.value = true;

        getAllBusinesses(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        ).then(() => {
          isLoadingUserBusiness.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de empresas:", error);
      }
    };

    const handleRemoveBusiness = async (user_id: number) => {
      loadingStore.setLoading("Removendo empresa...");

      await deleteUserById(user_id);

      loadingStore.setLoadingOff();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      if (user.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (user.value.type == UserType.BUSINESS) await fetchUserBusinessData();
    };

    const handleCreateAddress = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...newAddressFormPayload } = newAddressForm;

      const response = await createAddress(newAddressFormPayload);

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      if (response) isAddressModalOpen.value = false;
    };

    const updatePostalCode = (value: string) => {
      if (value.length == 9)
        getAddress(value).then(() => {
          if (address.value) {
            newAddressForm.street = address.value.logradouro;
            newAddressForm.complement = address.value.complemento;
            newAddressForm.neighborhood = address.value.bairro;
            newAddressForm.city = address.value.localidade;
            newAddressForm.state = address.value.uf;
          }
        });
    };

    const v$ = useVuelidate(newAddressFormRules, newAddressForm);

    onMounted(async () => {
      if (user.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (user.value.type == UserType.BUSINESS) await fetchUserBusinessData();
    });

    return {
      v$,
      user,
      columns,
      pagination,
      isLoadingUserBusiness,
      isAddressModalOpen,
      users,
      newAddressForm,
      updatePostalCode,
      handleCreateAddress,
      handleUpdatePageProps,
      handleRemoveBusiness,
    };
  },
});
</script>
